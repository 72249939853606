.article-list {
  padding:0;
  margin:0;
  list-style:none;

  .node-article {
    @extend .clearfix;

    @include breakpoint(a,max) {
      background:#fff;
      padding:10px;
    }

    // Add spacing border between articles.
    @include breakpoint(a) {
      border-bottom:1px solid $c-light-grey;
      padding-bottom: $gap;
    }

    header a {
      text-decoration:none;
      color:$c-default;

      &:hover {
        text-decoration:underline;
        color:#000;
      }
    }

    h2 {
      // Normally I use margin for this because the browser default is also
      // margin, but here I need padding because we have a link wrapper for the
      // h2 and the img and if I use margin the hover styling won't get
      // triggered in the spacing area which results in flickering.
      // But the margin must get a reset anyway.
      padding:0 0 0.5em 0;
      margin:0;
    }

    img {
      float:left;
      max-width:50%; // @todo nur für design vorübergehend
      margin:0 10px 10px 0;
    }

    p {
      margin-top:0;
    }

    .links {
      float:right;
      padding:0;
      list-style:none;
    }
  }
}

.article-list-item {
  margin-bottom: $gap;

  // Remove border from last article.
  &:last-child .node-article {
    @include breakpoint(a) {
      border:none;
    }
  }
}
