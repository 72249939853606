#edit-field-no-description-explenation,
#edit-field-free-of-commission,
#edit-field-economic-relationship,
#edit-field-costs-beside,
#edit-field-explanation {
  p {
    display:inline;
  }
}

#edit-field-certificate {
  p {
    margin-top: 0;
    margin-left: 1em;
    font-weight: $font-light;
  }
}

// Position edit city link.
.city-edit-link {
  top: -1.2em;
  position: relative;

  @include breakpoint(a) {
    left: 26.4em;
  }
}


.node-property-form {
  .form-wrapper {
    max-width: 1300px;
  }

  .field-name-field-property-images {
    fieldset {
      background: none;
      border: 0;
    }

    table {
      border: 1px solid $c-light-grey;

      .image-widget-data {
        margin-left: 310px;

        .form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
