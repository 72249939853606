.property-status-count {
  width: auto;

  .total {
    font-weight: $font-bold;
  }
}

.quota-ok,
.quota-warning,
.quota-nok {
  color: white;
  padding: 0.2em;
}

.quota-ok {
  background:$c-bg-green;
}

.quota-warning {
  background:$c-bg-yellow;
}

.quota-nok {
  background:$c-bg-red;
}

.field-name-field-agency-status {
  .field-items {
    color: white;
    padding: 0.2em;
    text-align: center;
    white-space: nowrap;
  }

  // active
  &.entity-id-1782 {
    .field-items {
      background:$c-bg-green;
    }
  }

  // inactive
  &.entity-id-1783 {
    .field-items {
      background:$c-bg-light-grey;
      color:$c-light-grey;
    }
  }

  // Reset the color for the editable form element.
  .editablefield-item {
    color: $c-default;
  }
}
