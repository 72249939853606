.page-user-bookmarks {
  #page-title {
    margin:0 0 10px 0;

    @include breakpoint(a,max) {
      display:none;
    }
  }

  // Temporary hide the local tasks on this page, because they are not styled for smartphones.
  #local-tasks {
    @include breakpoint(a,max) {
      display:none;
    }
  }

  // We must use a separate column splitting for the bookmarks compared to the other property listings because we don't
  // have a sidebar.
  .property-list {
    @include breakpoint(b) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
