/*
==========================================================================
Login
==========================================================================
*/
#user-login {
  max-width:500px;
}

/*
==========================================================================
Registration
==========================================================================
*/
#user-register-form {
  max-width:500px;
}

/*
==========================================================================
Request Password
==========================================================================
*/
#user-pass {
  max-width:500px;
}

/*
==========================================================================
Articles view
==========================================================================
*/
.view-articles {
  .field-name-field-article-status {
    .field-items {
      padding:0.2em;
      text-align:center;
      white-space:nowrap;
    }
  }

  .views-field-field-article-image {
    min-width: 120px;
  }
}

/*
==========================================================================
Properties view
==========================================================================
*/
.view-properties {
  .field-name-field-property-status,
  .field-name-field-property-paid-status {
    .field-items {
      padding:0.2em;
      text-align:center;
      white-space:nowrap;
    }
  }

  .views-field-field-property-images {
    min-width: 120px;
  }
}

.views-field-field-property-top {
  .field-name-field-property-top:after {
    font-size: 1.2em;
    @extend .icon;
  }

  &.value-0 {
    .field.field-name-field-property-top:after {
      color: $c-bg-red;
      @extend .icon-cancel-circle;
    }
  }

  &.value-1 {
    .field.field-name-field-property-top:after {
      color: $c-bg-green;
      @extend .icon-checkmark-circle;
    }
  }
}

/*
==========================================================================
Requests view
==========================================================================
*/
.view-requests {
  .field-name-field-request-status {
    .field-items {
      padding:0.2em;
      text-align:center;
      white-space:nowrap;
    }
  }
}

/*
==========================================================================
Tasks view
==========================================================================
*/
.view-tasks {
  .field-name-field-assignee,
  .field-name-field-task-type,
  .field-name-field-task-status {
    .field-items {
      padding:0.2em;
      text-align:center;
      white-space:nowrap;
    }
  }
}

/*
==========================================================================
Saved searches
==========================================================================
*/
.page-saved-searches .views-field-results {
  min-width: 400px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
  }
}

/*
==========================================================================
Matching search clients view
==========================================================================
*/
.view-matching-search-clients {
  .hit-status {
    padding:0.2em;
    text-align:center;
    white-space:nowrap;
    color: #fff;

    &.offered {
      background:$c-bg-green;
    }

    &.not-suitable {
      background:$c-bg-red;
    }

    &.undefined {
      background:$c-bg-light-grey;
      color:$c-light-grey;
    }
  }

  .views-field-field-sw-notifiy-interval {
    span {
      padding:0.2em;
      text-align:center;
      white-space:nowrap;
    }

    // disabled
    &.interval-id-1874 span {
      background:$c-bg-red;
      color: #fff;
    }
  }
}

.views-field-field-search-wish-property-type,
.views-field-field-search-wish-category,
.views-field views-field-field-search-wish-country,
.views-field-field-search-wish-region,
.views-field-field-property-price {
  white-space: nowrap;
}

/*
==========================================================================
New mail form
==========================================================================
*/
#mh-activity-mailto-form {
  @include breakpoint(b) {
    display: flex;
    align-items: flex-start;
    gap: $gap;
  }
}
