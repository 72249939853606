body.cke_ltr {
  background: none;
}

/*
==========================================================================
CKeditor styling copied from adminimal theme
==========================================================================
*/
body .cke_reset_all,body  .cke_reset_all * {
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  text-shadow: none;
}

body .ckeditor_links {
  border-left: 1px solid #DDDDDD;
  border-right: 1px solid #DDDDDD;
  display: block;
  padding: 10px;
  width: auto;
}

body .ckeditor_links:hover {
  background: #eee;
  text-decoration: none;
}

body .cke_chrome {
  box-shadow: none;
}

body .cke_top {
  background: #eee;
}

body .cke_toolgroup {
  background: #fff;
  border: 1px solid #ccc;
}


body .cke_combo_button {
  background: #fff;
  border: 1px solid #ccc;
}

body .cke_combo_off a.cke_combo_button:hover {
  background: #fff;
  border: 1px solid #0074BD;
}

body .cke_button_on {
  background: #fff;
  box-shadow: none;
  border-bottom: 3px solid #0074BD;
}

body .cke_bottom {
  background: #eee;
}

body .cke_combo_off a.cke_combo_button:active,body  .cke_combo_on a.cke_combo_button {
  border: 1px solid #0074BD;
  border-bottom-width: 3px;
  background: #fff;
  box-shadow: none;
}

body a.cke_button_on:hover,body  a.cke_button_on:focus,body  a.cke_button_on:active {
  box-shadow: none;
}

body a.cke_button_off:hover,
body a.cke_button_off:focus, a.cke_button_off:active,
body a.cke_button_disabled:hover,
body a.cke_button_disabled:focus,
body a.cke_button_disabled:active {
  background: #eee;
  box-shadow: none;
  border-color: #0074BD;
}

body .cke_reset_all label {
  display: inline-block;
  margin-top: 5px;
  padding-bottom: 2px;
  position: relative;
}

body .cke_dialog_body label.cke_required {
  font-weight: $font-light;
}

body .cke_reset_all .cke_dialog_body {
  font-family: "Roboto", "Segoe UI", "Helvetica", sans-serif;
  border: 1px solid #CCCCCC;
}

body .cke_reset_all fieldset {
  border: 1px solid #DDDDDD;
  border-top: 3px solid #0074BD;
  padding: 10px;
}

body .cke_reset_all legend {
  font-family: "Roboto", "Segoe UI", "Helvetica", sans-serif;
  font-size: 18px;
  font-weight: $font-light;
  padding: 0 0.5em;
}

body .cke_combopanel {
  height: 170px;
  width: 170px;
  border: 1px solid #DDDDDD;
  margin-top: 3px;
}

body .cke_dialog_footer {
  background: #eee;
}

body a.cke_dialog_tab:hover {
  text-decoration: none;
  background: #fff;
  color: #0074BD;
}

body select.cke_dialog_ui_input_select {
  box-shadow: none;
}

body input.cke_dialog_ui_input_text,
body input.cke_dialog_ui_input_password,
body textarea.cke_dialog_ui_input_textarea {
  box-shadow: none;
  border-color: #CCCCCC;
}

body .cke_dialog_title {
  background: #333333;
  border-bottom: none;
  border-radius: 0;
  box-shadow: none;
  color: #FFFFFF;
  font-size: 18px;
  text-shadow: none;
}

body .cke_dialog_ui_vbox_child label {
  top: -2px;
}

body input.cke_dialog_ui_input_text:focus,
body input.cke_dialog_ui_input_password:focus,
body textarea.cke_dialog_ui_input_textarea:focus,
body select.cke_dialog_ui_input_select:focus {
  border-color: #0074BD;
}

body a.cke_dialog_ui_button:focus,
body a.cke_dialog_ui_button:hover,
body a.cke_dialog_ui_button:active {
  border-color: #0074BD;
  box-shadow: none;
  text-decoration: none;
  color: #fff;
  background: #0074BD;
}

body a.cke_dialog_ui_button {
  background: #eee;
  padding: 4px 0;
}

body a.cke_dialog_ui_button:focus span,
body a.cke_dialog_ui_button:hover span,
body a.cke_dialog_ui_button:active span {
  color: #fff;
}

body .cke_ltr .cke_dialog_close_button {
  background: #B73939;
  right: 10px;
  top: 1px;
  width: 45px;
}

body .cke_ltr .cke_dialog_close_button:hover {
  background-color: #e55454;
}

body a.cke_dialog_ui_button_ok {
  background: #4D8F46;
  border-color: #4D8F46;
  box-shadow: none;
}

body a.cke_dialog_ui_button_ok:hover {
  background: #0A6700;
  border-color: #0A6700;
}