@media print {
  @page {
    size: A4;
    margin: 1.5cm 2cm;
  }

  html,
  body {
    font-family:sans-serif;
    font-size:10pt;
    color:#000;
  }

  p {
    page-break-inside:avoid;
  }

  #description,
  #images,
  #secondary-features {
    // Fix offset problem of containing divide.
    padding-top:0.01cm;
    page-break-before:always;
  }

  body,
  #header,
  #vtk-section-one {
    background:none;
  }

  #navigation,
  #unit-switcher,
  #language-switcher,
  #currency-switcher,
  .search-trigger,
  .request-trigger,
  .backtotop-trigger,
  body.node-type-property.page-node-view #vtk-section-two,
  #local-tasks,
  #back-button,
  #breadcrumb,
  #nav-trigger,
  #jumplist,
  .node-type-property.page-node-view #sidebar-bottom,
  #vtk-section-four,
  #vtk-section-five,
  #block-masquerade-masquerade,
  #block-user-online,
  .dev-query,
  .front #sidebar-top {
    display: none;
  }

  #vtk-section-one,
  #vtk-section-two,
  #vtk-section-three,
  #vtk-section-four,
  #vtk-section-five {
    border:none;

    .vtk-section-margin,
    .vtk-section-container {
      max-width:none;
      width:100%;
      margin:0;
      padding:0;
    }
  }

  body.node-type-property.page-node-view #main-content {
    max-width:none;
    width:100%;
    margin:0;
    padding:0;
  }


  body.node-type-property.page-node-view #images img {
    display: none;
    max-width:49%;
    float:left;
    margin-right:2%;

    &:nth-child(2n) {
      margin-right:0;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      display:block;
    }
  }

  .property-list-item {
    width: 49%;
    float: left;
    margin-right: 2%;

    &:nth-child(2n) {
      margin-right:0;
    }

    .node-property {
      page-break-inside:avoid;
      border:1px solid $c-lighter-grey;
    }
  }

  .article-list .node-article {
    page-break-inside:avoid;
    border-top: 1px solid $c-lighter-grey;
  }

  #logo img {
    margin-top:0;
  }

  #name-and-slogan {
    display: block;
    position: static;
    color:#000;
    text-align: center;
    padding:0;
    margin-bottom:0.5em;

    .name {
      font-size:18pt;
    }

    .slogan {
      font-size:12pt;

      br {
        content: "";
      }
    }
  }

  h1 {
    font-size:18pt;
    text-align:center;
    margin-bottom:0.5em;
    padding:0;
  }
}
