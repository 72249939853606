#sidebar-top {
  @include breakpoint(a,max) {
    // The sidebar is only visible after clicking search property. (Smartphones only)
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height:100%;
    z-index: 999;
    background:$c-grey-two;
    // Add scrollbar for sidebar.
    overflow: auto;
    // Transition from http://www.greywyvern.com/?post=337.
    visibility:hidden;
    opacity:0;
    transition:visibility 0s $defaultTransitionFunction $defaultTransitionDuration,opacity $defaultTransitionFunction $defaultTransitionDuration;
  }

  //@include breakpoint($sw-bravo) {
  //  .sidebar-container {
  //    @include breakpoint(a,max) {
  //      max-width:550px;
  //      margin:0 auto;
  //      background:#fff;
  //      width:100%;
  //      padding:10px;
  //    }
  //  }
  //}

  .sidebar-container {
    @include breakpoint(a,max) {
      max-width:550px;
      margin:0 auto;
      background:#fff;
      width:100%;
      padding:10px;
    }

    @include breakpoint(a) {
      column-count: 2;
    }

    @include breakpoint(b) {
      column-count: unset;
    }

    > div {
      break-inside: avoid;
    }
  }
}

// Body with this class. (Smartphones only)
.show-sidebar-top {
  @include breakpoint(a,max) {
    // Remove regular scrollbar.
    overflow:hidden;

    #sidebar-top {
      // Transition technic from http://www.greywyvern.com/?post=337.
      visibility:visible;
      opacity:1;
      transition-delay:0s;
    }
  }
}

// Trigger the hide action of the search ui. (Smartphones only)
#search-close-trigger {
  width:100%;
  border:none;
  line-height:50px;
  font-size:20px;
  color:#fff;
  outline:none;
  text-align:left;
  padding: 0 0 0 $gap;
  background:$c-grey-two;
  margin-bottom: $gap;
  // Add icon
  &:after {
    position:absolute;
    right:0;
    width:80px;
    font-size:24px;
    line-height:50px;
    text-align:center;
    border-left:2px solid #fff;
    @include click-area-color($c-mh-red);
    @extend .icon-arrow-left;
    @extend .icon;
  }

  @include breakpoint(a) {
    display:none;
  }
}

#search-customize-trigger {
  display:none;
  width:100%;
  border:none;
  line-height:50px;
  font-size:20px;
  color:#fff;
  outline:none;
  text-align:left;
  padding: 0 0 0 $gap;
  background:$c-grey-two;
  position:relative;
  // Add icon
  &:after {
    position:absolute;
    right:0;
    width:50px;
    font-size:24px;
    line-height:50px;
    text-align:center;
    @extend .icon-arrow-left;
    @extend .icon;
    @include def-transition(transform);

  }

  // Display block only on search page.
}

.block-facetapi {
  // Set margin for facet api blocks.
  margin:20px 0;

  // Hide block titles of facet blocks. We reset this on the search page.
  h2 {
    display:none;
  }
}

// Hide facet limit link. We reset this on the search page.
.facetapi-limit-link {
  display:none;
}

#searchbox {
  text-align: center;

  // Defaults to display none on bigger screens. The pages where we want to use
  // the box we need to overwrite the default. We hide it only with css because
  // on the smartphone we always want to be able to use the search trigger bar.
  @include breakpoint(a) {
    display:none;
  }

  .title {
    @include breakpoint(a,max) {
      display:none;
    }

    font-size:26px;
    text-align:center;
    margin:0;
  }

  .block-facetapi {
    border: 1px solid $c-light-grey;
    background:$c-bg-light-grey;
    position: relative;
    overflow: hidden;
    z-index: 0;

    &:after {
      @extend .icon-arrow-down;
      @extend .icon;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.5em;
      line-height: 1.5em;
      z-index: -1;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      width: 100%;
      height: 100%;
      background: $c-link;
      opacity: 0.8;
    }

    &.loading-in:before  {
      transition: all 3s cubic-bezier(0, 0, 0, 1);
      right: 50%;
    }
    &.loading-out:before  {
      transition: all 0.5s cubic-bezier(0, 0, 0, 1);
      right: 0%;
      opacity: 0;
    }
  }

  select {
    width:100%;
    border: none;
    appearance: none;
    background: none;

    &::-ms-expand {
      display: none;
    }
  }

  // Reset filters link
  .menu {
    margin:0;
    padding:0;
    list-style:none;
    text-align:center;
  }

  .form-submit {
    // Hide by default because it's useless without js.
    display:none;
    text-decoration:none;
    cursor:pointer;
    margin:0;

    &:after {
      @extend .icon-arrow-right;
      @extend .icon;
      margin-left:10px;
      position:relative;
      top:3px;
    }

    &:hover {
      color:#fff;
    }

    &.show {
      // Show with js.
      display:inline-block;
    }
  }
}

#views-exposed-form-wrapper {
  @include breakpoint(a,max) {
    display:none;
  }

  position:relative;

  .form-submit {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: black;
    // Hide text and set width, to span an invisible click area above the search
    // icon, because we can not add the icon to an input element.
    font-size:0;
    width:38px;
  }

  .form-item {
    // Alter default form styling.
    margin:0;
  }

  #edit-search-api-views-fulltext-wrapper {
    // Alter default views exposed form styling.
    margin:0;
    position:relative;

    // Add icon
    &:after {
      position:absolute;
      top:0;
      right:0;
      font-size:20px;
      padding:0 0.5em;
      line-height:38px;
      @extend .icon-search;
      @extend .icon;
    }
  }

  // Fix icon position if there is a contextual links region.
  .contextual-links-region {
    .form-item-search-api-views-fulltext {
      &:after {
        top:0;
        right:0;
      }
    }
  }

  #edit-sort-bef-combine-wrapper {
    padding:0;
  }
}

/*
==========================================================================
Range slider
==========================================================================
*/
.form-item-range-from,
.form-item-range-to {
  width:45%;

  // Hide from and to label.
  label {
    display:none;
  }

  input {
    width:100%;
  }
}

.form-item-range-from {
  float:left;
}

.range-seperator {
  float:left;
  width:10%;
  line-height:2.4em;
  text-align:center;
}

.form-item-range-to {
  float:right;
}

.range-slider-box {
  clear:both;
  padding:0 10px;
}

.range-slider {
  height:0.4em;

  .ui-widget-header {
    background:$c-mh-red;
  }

  .ui-slider-handle {
    border-color:#aaa;
    top:-0.4em;
    border-radius: 100%;
    background:#fff;

    &:after {
      content:'';
      display:block;
      height:0.5em;
      width:0.5em;
      margin:0.3em;
      background:$c-mh-red;
      border-radius: 100%;
    }
  }
}
