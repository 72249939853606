table {
  width: 100%;
  margin: 0 0 10px;
}

table td,table th {
  padding: 8px 10px;
}

tr.even,tr.odd {
  background: $c-bg-light-grey;
}

tr.odd {
  background: #fff;
}

tr.even:hover, tr.odd:hover {
  background: #FFFFBF;
}

tr.drag {
  background: #fe7;
}

tr.drag-previous {
  background: #ffb;
}

table th {
  background: darken($c-bg-light-grey, 10%);
  padding: 0.5em;
  white-space:nowrap;
}

table th.active {
  background: $c-link;
  color: #fff;
}

table th a {
  display: block;
}

table th.active a {
  padding: 0 25px 0 0;/* LTR */
  color: #fff;
}

table th.active img {
  margin-left:0.5em;
}
