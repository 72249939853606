.minimal-share {
  a {
    font-size:0;
    line-height:0;
    min-width:30px;
    text-align:center;
    margin-right:5px;

    &:before {
      font-size:20px;
      line-height:30px;
      @extend .icon;
    }
  }

  .facebook:before {
    @extend .icon-facebook2;
  }
  .twitter:before {
    @extend .icon-twitter2;
  }
  .gplus:before {
    @extend .icon-google-plus;
  }
  .linkedin:before {
    @extend .icon-linkedin;
  }
  .pinterest:before {
    @extend .icon-pinterest;
  }
}