@font-face {
  font-family: 'mh_icons';
  src:    url('../mh_icons/fonts/mh_icons.eot?t73f0q');
  src:    url('../mh_icons/fonts/mh_icons.eot?t73f0q#iefix') format('embedded-opentype'),
  url('../mh_icons/fonts/mh_icons.ttf?t73f0q') format('truetype'),
  url('../mh_icons/fonts/mh_icons.woff?t73f0q') format('woff'),
  url('../mh_icons/fonts/mh_icons.svg?t73f0q#mh_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Force webkit browsers to use svg because of better rendering
// @todo Test if really much better because this solution is not perfect. Now
// it downloads first the woff file and then the svg file.
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mh_icons';
    src: url('../mh_icons/fonts/mh_icons.svg?t73f0q#mh_icons') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.icon {
  font-family: 'mh_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spinner {
  content: "\e900";
}
.icon-images {
  content: "\e032";
}
.icon-file-check {
  content: "\e083";
}
.icon-file {
  content: "\e086";
}
.icon-copy {
  content: "\e08e";
}
.icon-tag {
  content: "\e0bc";
}
.icon-coins {
  content: "\e0d4";
}
.icon-phone {
  content: "\e0dc";
}
.icon-address-book {
  content: "\e0e9";
}
.icon-envelop {
  content: "\e0ec";
}
.icon-users {
  content: "\e198";
}
.icon-user {
  content: "\e199";
}
.icon-search {
  content: "\e1b7";
}
.icon-key {
  content: "\e1cb";
}
.icon-stats-up {
  content: "\e207";
}
.icon-clipboard {
  content: "\e298";
}
.icon-list {
  content: "\e299";
}
.icon-grid {
  content: "\e2a0";
}
.icon-earth {
  content: "\e2c7";
}
.icon-star {
  content: "\e2ff";
}
.icon-star2 {
  content: "\e301";
}
.icon-notification {
  content: "\e358";
}
.icon-info {
  content: "\e363";
}
.icon-cancel-circle {
  content: "\e366";
}
.icon-checkmark-circle {
  content: "\e368";
}
.icon-checkmark {
  content: "\e372";
}
.icon-arrow-down {
  content: "\e3b6";
}
.icon-arrow-up-right {
  content: "\e3d2";
}
.icon-arrow-right2 {
  content: "\e3d3";
}
.icon-arrow-down-left {
  content: "\e3d6";
}
.icon-arrow-up {
  content: "\e410";
}
.icon-arrow-right {
  content: "\e411";
}
.icon-arrow-left {
  content: "\e413";
}
.icon-radio-unchecked {
  content: "\e439";
}
.icon-googleplus {
  content: "\e496";
}
.icon-facebook {
  content: "\e49b";
}
.icon-twitter {
  content: "\e49f";
}
.icon-mh-for-partner {
  content: "\e604";
}
.icon-mh-for-buyer {
  content: "\e605";
}
.icon-masterhomes {
  content: "\e606";
}
.icon-commission-forward {
  content: "\e607";
}
.icon-user-edit {
  content: "\e603";
}
.icon-manage-requests {
  content: "\e600";
}
.icon-manage-properties {
  content: "\e601";
}
.icon-add-property {
  content: "\e602";
}
.icon-twitter2 {
  content: "\f099";
}
.icon-facebook2 {
  content: "\f09a";
}
.icon-navicon {
  content: "\f0c9";
}
.icon-pinterest {
  content: "\f0d2";
}
.icon-google-plus {
  content: "\f0d5";
}
.icon-linkedin {
  content: "\f0e1";
}
