.page-node-59718.page-node-view {
  #vtk-section-one {
    margin-bottom: 0;
  }

  #vtk-section-three .vtk-section-margin {
    margin: 0;
  }

  .vtk-section-container {
    max-width: 100%;
  }

  #breadcrumb {
    display: none;
  }

  // Hide "Immobilien suchen" menu link. Wollte Roman so.
  #main-menu {
    .real-estate {
      display: none;
    }
  }

  .action-links {
    display: none;
  }

  .become-partner-head {
    padding: 4em 0;
    position: relative;

    @include breakpoint(a) {
      padding: 8em 0;
    }
  }

  .become-partner-head-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding: 0 1.5em;
    position: relative;
    z-index: 7;
    color: white;

    ul {
      padding: 0;

      @include breakpoint(a) {
        list-style-position: inside;
      }
    }

    li {
      line-height: 1.5em;
    }
  }

  .become-partner-head-bg-washed {
    background: hsla(346,10,15,0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
  }

  .become-partner-head-bg {
    background: url("/sites/all/themes/mh_theme/become-partner-bg.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  h1 {
    font-size: 2em;
    font-weight: $font-light;
    background: none;

    .name,
    .slogan {
      display: block;
    }

    .slogan {
      max-width: 600px;
      margin: 0.5em auto 0 auto;
      font-weight: $font-light;
      font-size: 0.8em;
      text-align: left;
    }
  }

  #main-content {
    text-align: center;
    position: relative;
    font-size: 1.3em;
  }

  .style-content-important {
    font-size: 2em;
    font-weight: $font-light;
    margin: 2em 0;
  }

  .models-head {
    background: $c-bg-light-grey;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 2em;
    font-size: 2em;
    font-weight: $font-light;
  }

  .models {
    padding: 50px 1.5em;
    background: $c-bg-light-grey;
  }

  .swiper-pagination {
    bottom: initial;
  }

  .model {
    // Material Design Shadow
    //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    width: 270px;
    margin: 0 0.5rem;
    background: #f0f0f0;

    @include breakpoint(a) {
      width: 290px;
    }
  }

  .small-info {
    font-size: 0.8em;
    line-height: 1em;
    color: $c-light-grey;
    background: $c-bg-light-grey;
    margin: 0;
    padding: 0 0 2em 0;
  }

  .divide2 {
    margin: 3em 0;
    height: 1px;
    background-image: linear-gradient(to right, #ffffff, $c-light-grey, #ffffff);
  }
}

.model-name {
  background: $c-lighter-grey;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: $font-light;
  padding: 1em 0;
  margin: 0;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  @include def-transition(all);

  :hover > & {
    background: $c-bg-green !important;
    color: white !important;
  }

  .swiper-slide-active > & {
    background: $c-bg-green;
    color: white;

    @include breakpoint(a) {
      background: $c-lighter-grey;
      color: $c-default;
    }
  }

  @include breakpoint(a) {
    font-size: 1.8rem;
    padding: 1.3em 0;
  }
}

.model-price {
  font-size: 2rem;
  padding: 0.4em 0;
  margin: 0;

  .sign {
    font-size: 0.6em;
    vertical-align: middle;
  }

  .month {
    font-size: 0.4em;
  }

  @include breakpoint(a) {
    font-size: 2.5rem;
    padding: 0.6em 0;
  }
}

.model-discount {
  font-size: 1rem;
  color: $c-bg-green;
  line-height: 1.5em;
  padding: 0 1rem 1.2em 1rem;
  text-align: left;
}

.model-features {
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
}

$c-middle-grey:#aaa;

.model-feature {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 80px;
  border-bottom: 1px solid $c-bg-light-grey;
  font-size: 1rem;
  text-align: left;

  &.fw {
    flex-wrap: wrap;
  }

  .label {
    color: $c-middle-grey;
    display: block;
  }

  &.checkmark {
    color: $c-middle-grey;

    &:before {
      @extend .icon-checkmark;
      @extend .icon;
      margin-right: 0.5em;
      font-size:1.5em;
      color: $c-bg-green;
    }
  }

  @include breakpoint(a) {
    height: 90px;
    font-size: 1.2rem;
  }
}

.quota {
  font-size: 1.5rem;
  font-weight: $font-medium;

  .value {
    margin-right: 0.2em;
  }

  .label {
    font-size: 1.2rem;
    font-weight: $font-normal;
  }
}

.model-action {
  padding: 1.5em 0;
  background: white;
}

.open-request-form {
  height: 3rem;
  color: $c-bg-green;
  text-decoration: none;
  padding: 0.5em 0.8em;
  border: 1px solid $c-bg-green;
  border-radius: 4px;
  @include def-transition(all);

  &:hover,
  :hover > * > & {
    color: white !important;
    text-decoration: none;
    background: $c-bg-green !important;
  }

  .swiper-slide-active > * > & {
    background: $c-bg-green;
    color: white;

    @include breakpoint(a) {
      background: white;
      color: $c-bg-green;
    }
  }
}

.advantages {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5em;
  text-align: left;
  padding: 0 1.5em 5em 1.5em;

  @include breakpoint(a) {
    grid-template-columns: 1fr 1fr;
  }
}

.advantage {
  background: $c-bg-light-grey;
  padding: 1em 1.5em;

  h3 {
    color: $c-bg-green;
  }
}

div.ad-request-popup {
  display: flex;
  flex-direction: column;
  background: $c-bg-light-grey;

  .modal-content {
    padding: 1em 2em;
  }

  .modal-header {
    background: white;
    padding: 1em 2em;
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.075);
  }

  .modal-title {
    white-space: normal;
    color: $c-default;
  }

  input[type=tel] {
    width: 100%;
  }

  input[type=tel],
  input[type=text],
  input[type=email],
  select {
    background: white;
  }

  .form-item {
    label {
      float: none;
    }
  }
}
