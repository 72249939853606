.plupload_wrapper {
  font: inherit;
}

.plupload_filelist_header,
.plupload_filelist_footer {
  background: #eee;
}

.plupload_filelist_footer {
  height: auto;
}

.plupload_button {
  font-size: 1.3em;
}