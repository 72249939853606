body.node-type-contact-message {
  .node-contact-message {
    max-width:500px;
  }

  .field-name-field-requestor,
  .field-name-field-request-message,
  .field-name-field-contact-message-subject {
    margin-top:2em;
    border-top:1px solid $c-light-grey;
    border-left:1px solid $c-light-grey;

    .field-label {
      background:$c-bg-light-grey;
      padding:0.5em;
      font-weight:$font-normal;
    }

    .field-items {
      margin:0.5em;
    }

    // Reset styling on child fields.
    .field {
      border:none;
      line-height:1.5em;

      .field-label {
        background:none;
        padding:0;
        font-weight:$font-bold;
        width:auto;
        min-width:30%;
      }

      .field-items {
        margin:0;
      }
    }
  }
}
