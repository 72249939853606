.front {
  #vtk-section-one {
    // Remove default spacing from the bottom which was for the logo.
    margin-bottom:0;
  }

  #vtk-section-two {
    .vtk-section-container {
      position:relative;

      @include breakpoint(a) {
        // Set section before slideshow.
        z-index:20;
        // Set same height as slidedshow.
        height:550px;
      }
    }
  }

  .block-mh-media {
    display:none;

    @include breakpoint(a) {
      position:absolute;
      top:0;
      width:100%;
      display:block;
      max-height:550px;
      overflow:hidden;
    }

    img {
      max-width: 100%;
    }
  }

  // Hide royalslider by default.
  .royalSlider {
    width: 100%;
    height: 550px;
    display:none;

    @include breakpoint(a) {
      display:block;
    }
  }

  .rsContent img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  #vtk-section-three {
    @include breakpoint(a) {
      // Fix IE11 Bug. Missing Margin between section two and three.
      border-top:1px solid transparent;
    }



    .vtk-section-container {
      @include breakpoint(b) {
        display: grid;
        grid-template-columns: minmax(0, 4fr) minmax(0, 8.3fr);
        gap: 0 $gap;
      }

      > * {
        @include breakpoint(a) {
          grid-column: span 2;
        }
      }

      #sidebar-bottom {
        @include breakpoint(b) {
          grid-column: 1 / span 1;
          grid-row: 1;
          align-self: start;
        }
      }

      #main-content {
        @include breakpoint(b) {
          grid-column: 2 / span 1;
          grid-row: 1;
        }
      }
    }
  }

  .property-list {
    @include breakpoint(c) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  #searchbox {
    // Display seachbox because default is hidden.
    display:block;

    @include breakpoint(a) {
      position:absolute;
      z-index:50;
      top:220px;
      width:300px;
      background:#fff;
      padding:20px;
    }
  }

  #views-exposed-form-wrapper {
    position:absolute;
    z-index:50;
    top:540px;
    width:300px;
    background:#fff;
    padding:20px;

    // Correct icon position on frontpage because of extra padding.
    .form-submit {
      top: 20px;
      right: 20px;
    }

    // Correct icon position on frontpage because of extra padding.
    .form-item-search-api-views-fulltext {
      &:after {
        top:20px;
        right:20px;
      }
    }

    // Correct icon position on frontpage because of extra padding.
    .contextual-links-region {
      .form-submit {
        top: 0;
        right: 0;
      }

      .form-item-search-api-views-fulltext {
        &:after {
          top:0;
          right:0;
        }
      }
    }

    // Disable Throbber
    input.form-autocomplete {
      background-image:none;
    }
  }

  #edit-search-api-views-fulltext-wrapper {
    // Alter default views exposed form styling.
    float:none;

    // Alter default form styling.
    .form-item {
      padding:0;
    }

    input {
      width:100%;
    }
  }

  // Hide sort option on frontpage.
  #edit-sort-bef-combine-wrapper {
    display:none;
  }

  #sidebar-bottom {
    @include breakpoint(a) {
      margin-top: $gap;
    }

    @include breakpoint(b) {
      margin-top: 0;
    }
  }
}

#name-and-slogan {
  display:none;
  position:absolute;
  right:0;
  bottom:10%;
  margin:0;
  color:#fff;
  text-align:right;

  @include breakpoint(a) {
    display:block;
  }

  .name {
    font-size:36px;
  }

  .slogan {
    display:block;
    font-size:24px;
    font-weight:$font-light;
  }
}
