.property-list {
  padding:0;
  margin:0;
  list-style:none;

  @include breakpoint(a) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $gap;
  }

  @include breakpoint(c) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  > li {
    margin-bottom: $gap;

    @include breakpoint(a) {
      margin-bottom: 0;
    }
  }
}
