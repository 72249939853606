.view {
  input[type="submit"] {
    float:left;
  }

  .view-content {
    // Clear view content to prevent overlapping.
    clear:both;
  }
}

.view-filters {
  position:relative;
  z-index:1;
  @extend .clearfix;
  padding:1em;
  background:$c-bg-light-grey;

  input[type='text'],
  select {
    background:#fff;
  }
}

.views-exposed-widget {
  float: left;
  margin-right:2em;

  label {
    padding:0.5em;
  }

  .form-checkboxes,
  .form-radio {
    .form-item {
      margin:0.5em 0;
    }
  }
}

// Object ID field
#edit-field-object-id-value-wrapper {
  width:120px;

  input {
    max-width:100%;
  }
}

// Country/City field
#edit-shs-term-node-tid-depth-wrapper {
  width:220px;

  select {
    max-width:100%;
  }
}

// Checkbox dropdown UI
#edit-field-assignee-target-id-wrapper,
#edit-field-task-priority-target-id-wrapper,
#edit-field-task-status-target-id-wrapper,
#edit-field-task-type-target-id-wrapper,
#edit-field-property-type-target-id-wrapper,
#edit-field-property-contract-model-target-id-wrapper,
#edit-field-property-paid-status-target-id-wrapper,
#edit-field-property-sold-status-value-wrapper,
#edit-field-property-status-target-id-wrapper,
#edit-field-request-status-target-id-wrapper,
#edit-property-types-wrapper {
  position:relative;

  label {
    border:1px solid transparent;
    border-bottom:none;

    &:after {
      @extend .icon;
      @extend .icon-arrow-down;
      float:right;
      margin-top:2px;
    }

    &.active {
      color:#fff;
      background:$c-link;
    }
  }

  // Reset label styles on child elements.
  .form-item label {
    border:none;

    &:after {
      content:none;
    }
  }

  >.form-type-select {
    border:1px solid $c-light-grey;
    padding:0 1em;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    position:absolute;
    background:#fff;
    z-index:-1;
    margin-top:-1px;
    display:none;
    min-width:100%;
    white-space:nowrap;
  }

  &:hover {
    z-index:1;

    label {
      color:$c-mh-red;
      background:#fff;
      border-color:$c-light-grey;
    }

    // Reset label styles on child elements.
    .form-item label {
      color:$c-default;
      background:none;
    }

    .form-type-select {
      display:block;
    }
  }
}

//#edit-field-assignee-target-id-wrapper {
//  >.form-type-select {
//    padding-top: 3.5em;
//  }
//
//  .views-operator {
//    position: absolute;
//    display: none;
//
//    .form-item {
//      margin-bottom: 0;
//      padding: 1em;
//    }
//  }
//
//  &:hover {
//    .views-operator {
//      display: block;
//    }
//  }
//}
