/*
==========================================================================
Global variables
==========================================================================
*/
// Default font color.
$c-default:#333;
// Masterhomes red.
$c-mh-red:#7e001d;
// Default link color.
$c-link:#007BFF;
// Default background color for selections.
$c-bg-selection:#308cfb;
// Basic dark grey (header background).
$c-grey-one:#1D2028;
// Dark grey background (smartphone body).
$c-grey-two:desaturate(lighten($c-grey-one,6%),2%);
// Backround for form elements on white background.
$c-bg-light-grey:#f5f5f5;
// Light grey for borders and splitting lines.
$c-light-grey:#ccc;
// Lighter grey for borders and splitting lines which should be softer.
$c-lighter-grey:#ddd;
// Used as request status background.
$c-bg-yellow:#f4d01e;
// Used as request status background.
$c-bg-green:#0ec310;
// Used as request status background.
$c-bg-red:#c30e0e;
// Used as task type background
$c-bg-orange: #fb9500;
// Used as task type background
$c-bg-pink: #cc48e4;
// Colors for task list assignees.
$c-bg-n1: #74B7E8;
$c-bg-n2: #EFB6B6;
$c-bg-n3: #96e5e7;
$c-bg-n4: #e0b1f0;
$c-bg-n5: #88c88d;
$c-bg-n6: #8acfff;

// Default transition
$defaultTransitionDuration:367ms;
$defaultTransitionFunction:cubic-bezier(0.1,0.9,0.2,1);

// Breakpoint Settings
// min / max — default is min (mobile first)
$media-direction: min;

// Breakpoints
// A Sass Map for all Breakpoints you need. But keep it simple! Just add Sizes that you need several times.
// Here are my basic set. I change the values a bit from project to project
$breakpoints: (
  'default': '',
  'a': 750px,
  'b': 1120px,
  'c': 1500px,
  'd': 970px,
);

// Font weight variables.
$font-light:300;
$font-normal:400;
$font-medium:500;
$font-bold:700;

// Grid gaps
$gap: 1.7rem;

/*
==========================================================================
Mixins and classes
==========================================================================
*/

// Breakpoint Mixin
// $breakpoint = die Größe die ihr braucht.
// $direction: $media-direction = das was ihr in den Settings eingestellt habt.
@mixin breakpoint($breakpoint,$direction: $media-direction) {
  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {

    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

    // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

// Clearfix class can be used as normal html class or with "@extend .clearfix;"
// directly in css
.clearfix {
  &:after {
    content:'.';
    display:block;
    height:0;
    clear:both;
    visibility:hidden;
  }
}

.span-nowrap {
  white-space: nowrap;
}

// Handles the consitenz of transitions.
@mixin def-transition($value) {
  transition: $value $defaultTransitionDuration $defaultTransitionFunction;
}

// Handles the consitenz of click areas stylings.
@mixin click-area-color($color) {
  background:$color;
  @include def-transition(background);

  &:hover {
    background:lighten($color,6%);
  }
}

@mixin btn-color($color) {
  @include click-area-color($color);
  border:1px solid darken($color, 5%);
  border-radius: 4px;
}
