#vtk-section-local-tasks {
  @include breakpoint(a) {
    margin-top: -50px;
    padding-top: 40px;
    background: $c-bg-light-grey;
    text-align: right;
  }

  .vtk-section-margin {
    margin: $gap;
    margin-bottom: $gap*-1;

    @include breakpoint(a) {
      margin:0;
    }
  }

  .vtk-section-container {
    @include breakpoint(a,max) {
      max-width:518px;
      margin:0 auto;
    }
  }

  ul.tabs.primary {
    background: desaturate(lighten($c-grey-two, 10%),5%);
    padding: .5em .5em 0 .5em;
    margin: 0;

    @include breakpoint(a) {
      background: none;
      padding: 0 $gap;
      border-bottom: 1px solid $c-light-grey;
    }

    li {
      @include breakpoint(a) {
        margin-bottom: -1px;
      }

      &.active a {
        background: desaturate(lighten($c-grey-two, 30%),5%);
        color: #fff;

        @include breakpoint(a) {
          background: #fff;
          border: 1px solid $c-light-grey;
          border-bottom: none;
          color: $c-default;
        }
      }
    }
  }

  ul.tabs.secondary {
    background: desaturate(lighten($c-grey-two, 30%),5%);
    padding: .5em .5em 0 .5em;
    margin: 0;

    @include breakpoint(a) {
      background: none;
      position: absolute;
      right: 0;
      padding: 0 $gap;
      margin-top: 0.5em;
    }

    li {
      &.active a {
        background: #fff;
        color: $c-default;

        @include breakpoint(a) {
          border-bottom: 4px solid $c-link;
        }
      }
    }
  }

  li {
    display: inline-block;
  }

  a {
    padding: 0.5em;
    margin-right: 0.2em;
    display: block;
    color: desaturate(lighten($c-grey-two, 60%),5%);

    @include breakpoint(a) {
      margin-right: .5em;
      color: lighten($c-default, 20%);
    }
  }
}
