body.node-type-property.page-node-view {
  #vtk-section-three .vtk-section-container {
    @include breakpoint(d) {
      display: grid;
      grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
      gap: 0 $gap;
    }

    @include breakpoint(c) {
      grid-template-columns: minmax(0, 9.6fr) minmax(0, 3fr);
    }

    > * {
      @include breakpoint(a) {
        grid-column: span 2;
      }
    }

    #main-content {
      @include breakpoint(d) {
        grid-column: 1 / span 1;
      }
    }

    #sidebar-bottom {
      background:#fff;
      padding:10px;

      @include breakpoint(d) {
        grid-column: 2 / span 1;
        padding:0;
      }

      .flag-outer {
        text-align:center;
        margin-bottom:1em;
      }
    }
  }

  .node-property {
    @include breakpoint(c) {
      display: grid;
      grid-template-columns: minmax(0, 6.3fr) minmax(0, 3fr);
      gap: 0 $gap;
    }
  }

  #content-first {
    @include breakpoint(a) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 $gap;
    }

    > * {
      @include breakpoint(a) {
        grid-column: span 2;
      }
    }
  }

  #teaser-image,
  #images {
    img {
      display:block;
      margin-bottom:1em;
    }
  }

  #teaser-image {
    @include breakpoint(a) {
      // Hide image because we create a slideshow.
      display:none;
    }
  }

  #slideshow {
    @extend .clearfix;
    position: relative;
  }

  .royalSlider {
    width:100%;
    max-width:800px;
    // Display slideshow only on big screens.
    display:none;
    overflow: hidden;
    // Default spacing before js is enabled.
    height: 540px;
    margin-bottom: 80px;

    @include breakpoint(a) {
      display:block;
    }

    .rsTmb {
      visibility: hidden;
    }

    // This class is set per js and we use it to reset the overflow.
    &.rsHor {
      overflow: visible;

      .rsTmb {
        visibility: visible;
      }
    }
  }

  .rsContent {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .landscape {
      width: 100%;
      height: auto;
    }

    .portrait {
      height: 100%;
      width: auto;
    }
  }

  .field-name-field-property-sold-status {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;

    @include breakpoint(a) {
      bottom: 120px;
    }

    .field-items {
      display: flex;
      justify-content: flex-start;
    }

    .field-item {
      background: $c-bg-green;
      color: white;
      padding: 10px;
      font-size: 1.2em;
      font-weight: $font-bold;

      @include breakpoint(a) {
        min-width: 150px;
      }
    }
  }

  .short-description {
    @include breakpoint(a) {
      grid-row: 2;
    }
  }

  #main-features {
    @include breakpoint(a) {
      grid-column: 2 / span 1;
      grid-row: 3;
    }

    .divide:after {
      @extend .icon-info;
    }

    ul {
      list-style:none;
      margin:0;
      padding:0;
    }

    li {
      text-align:center;
      line-height:1.7em;
      margin:10px 0;
    }

    .field-label {
      display:block;
      background:$c-bg-light-grey;
    }

    .item-list li {
      margin:0;
    }

    // We need to move this to #main-features otherwise the margin value will be
    // overritten.
    .field-property-sold-status,
    .field-price-on-request {
      display:block;
      background:$c-bg-light-grey;
      font-weight:$font-bold;
      margin-bottom:1.8em;
    }

    // Display categories as comma separated list.
    .field-categories .field-values {
      .field-value {
        display:inline;
        width:auto;

        &:after {
          content:', ';
        }

        &:last-child:after {
          content:none;
        }
      }
    }
  }

  .field-country-city {
    li {
      display: inline;

      // Reset default separator
      &:after {
        content: "";
        display: none;
      }
    }

    // Add separator to the city element so it is automatically not
    // visible if the city is hidden.
    .shs-term-selected:before {
      content: '/';
      margin: 0 0.5em;
    }
  }

  #description {
    @include breakpoint(a) {
      grid-column: 1 / span 1;
      grid-row: 3;
    }

    .divide:after {
      @extend .icon-file;
    }

    table {
      hyphens: auto;

      th, td {
        padding: 5px;
      }

      p {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0 1.2em;
      }
    }
  }

  #images {
    clear:both;

    .divide:after {
      @extend .icon-images;
    }
  }
  // Hide images because we create a slideshow.
  // Hide image/panorama container if there are no panoramas, so the divide is also hidden.
  .no-panorama {
    #images {
      @include breakpoint(a) {
        display:none;
      }
    }
  }
  // If there is a panorama just hide the images itself so the divide is visible for the panorama.
  .panorama {
    #images img {
      @include breakpoint(a) {
        // Hide images because we create a slideshow.
        display:none;
      }
    }
  }

  #secondary-features {
    @include breakpoint(c) {
      background:$c-bg-light-grey;
      padding:10px;
    }

    .divide {
      @include breakpoint(c) {
        // Hide when own column.
        display:none;
      }

      &:after {
        @extend .icon-clipboard;
      }
    }
  }

  #secondary-features,
  #sidebar-bottom {
    ul {
      list-style:none;
      margin:0;
      padding:0;
    }

    li {
      border-bottom:1px dotted $c-lighter-grey;
      margin-bottom:0.8em;
      padding-bottom:0.1em;

      li {
        border:none;
        margin-bottom:0;
        padding-bottom:0;
      }
    }

    .field-label {
      font-weight:$font-bold;
      display:inline-block;
      width:49%;
    }

    .field-value,
    .field-values {
      display:inline-block;
      width:49%;
      vertical-align: top;
      overflow-wrap: break-word;
    }

    .field-values {
      .field-value {
        display:inline;
        width:auto;

        &:after {
          content:', ';
        }

        &:last-child:after {
          content:none;
        }
      }
    }
  }

  .marginal-notes {
    font-size:0.8em;
    color:lighten($c-default, 35%);

    .field {
      margin:1em 0;
      clear:both;
    }

    .field-name-field-heat-energy-demand,
    .field-name-field-energy-performance {
      float:left;
    }

    .field-name-field-class-heat-energy-dema,
    .field-name-field-class-energy-performan {
      float:left;
      clear:none;
      margin-top:9px;
      margin-left:1em;
    }

    .field-name-field-energy-performance {
      margin-top:0;
    }

    .field-name-field-class-energy-performan {
      margin-top:-2px;
    }
  }

  .field-name-field-free-of-commission {
    &.entity-id-2023 {
      display: none;
    }
  }


  .minimal-share {
    float: left;
  }

  .links.inline {
    float: left;
    margin: 1em 0;
    padding: 0;

    li {
      display: inline;
    }

    .flag-wrapper.flag-bookmarks a {
      display: block;
      min-width: 30px;
      text-align: center;
      background: $c-grey-two;
      color:#ffff00;

      &:after {
        line-height: 30px;
      }
    }
  }

  .field-name-field-certificate .certificate-label {
    display:none;
  }

  #request-form {
    @include breakpoint(d) {
      background:$c-bg-light-grey;
      padding:10px;

      .divide {
        display:none;
      }

      #group-request-inputs input,
      textarea,
      select,
      input[type='text'] {
        background:#fff;
      }
    }

    // Alter default form styling.
    .form-item {
      padding:0;
    }

    .divide:after {
      @extend .icon-envelop;
    }

    input[type='text'],
    input[type='email'],
    textarea,
    select {
      width:100%;
    }

    // Hide content type settings by default.
    .vertical-tabs-list,
    .vertical-tabs-panes {
      display: none;
    }

    .vertical-tabs {
      margin: 0;
      background: lighten($c-light-grey, 10%);

      &:hover {
        .vertical-tabs-list,
        .vertical-tabs-panes {
          display: block;
        }
      }

      &:before {
        content: "Vertical tabs";
        display: block;
        text-align: center;
      }
    }
  }

  #group-request-inputs {
    @include breakpoint(d) {
      max-width:80%;
    }
  }

  .field-name-field-request-salutation {
    @include breakpoint(d) {
      max-width:170px;
    }
  }

  .node-request-form {
    @include breakpoint(a) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 $gap;
    }

    @include breakpoint(d) {
      display: block;
    }

    > * {
      grid-column: span 2;
    }
  }

  #group-request-inputs,
  .field-name-field-request-message {
    @include breakpoint(a) {
      grid-column: span 1;
    }
  }

  .field-name-field-property-status,
  .field-name-field-property-paid-status {
    margin-bottom:1em;

    .field-items {
      padding:0.4em;
      font-size:1.3em;
      text-transform:uppercase;
      text-align:center;
      font-weight:$font-bold;
    }
  }
}

.field-name-field-property-status,
.field-name-field-property-paid-status {
  .field-items {
    color:#fff;
  }

  // draft
  // review
  &.entity-id-48,
  &.entity-id-49 {
    .field-items {
      background:$c-bg-yellow;
    }
  }

  // translation
  &.entity-id-50 {
    .field-items {
      background:lighten($c-link, 30%);
    }
  }

  // translation
  &.entity-id-1391 {
    .field-items {
      background:$c-link;
    }
  }

  // active
  // paid
  &.entity-id-51,
  &.entity-id-190 {
    .field-items {
      background:$c-bg-green;
    }
  }

  // inactive
  // not-paid
  &.entity-id-52,
  &.entity-id-191 {
    .field-items {
      background:$c-bg-red;
    }
  }

  // archived
  // free-of-cost
  &.entity-id-189,
  &.entity-id-192 {
    .field-items {
      background:$c-bg-light-grey;
      color:$c-light-grey;
    }
  }

  // Reset the color for the editable form element.
  .editablefield-item {
    color: $c-default;
  }
}

// Style send to review flag.
.flag-send-property-to-review a {
  display:inline-block;
  padding:0 10px;
  line-height:36px;
  font-size:20px;
  color:#fff;
  @include btn-color($c-bg-green);

  &:hover {
    text-decoration:none;
  }

  &:after {
    @extend .icon-arrow-right;
    @extend .icon;
    margin-left:10px;
    position:relative;
    top:3px;
  }
}

#jumplist {
  @include breakpoint(a) {
    // Jumplist not necessary on bigger screens.
    display:none;
  }

  list-style:none;
  margin:0;
  padding:0;
  @extend .clearfix;
  // Prevent inline-block spacing bug.
  font-size:0;
  text-align:center;

  li {
    display:inline-block;
    // Reset 0 font-size
    font-size:16px;

    &:first-child a {
      border-radius: 4px 0 0 4px;
      //border-right:none;
      border-left:1px solid $c-lighter-grey;
    }

    &:last-child a {
      border-radius: 0 4px 4px 0;
      //border-left:none;
    }
  }

  a {
    float:left;
    min-width: 70px;
    @include click-area-color($c-bg-light-grey);
    text-decoration:none;
    color:$c-default;
    padding:10px;
    border:1px solid $c-lighter-grey;
    border-left: none;
    &:before {
      display:block;
      font-size:20px;
      @extend .icon;
      margin-bottom: 4px;
    }
  }

  .panorama {
    &:before {
      @extend .icon-spinner;
    }
  }
  .images {
    &:before {
      @extend .icon-images;
    }
  }
  .secondary-features {
    &:before {
      @extend .icon-clipboard;
    }
  }
  .request-form {
    &:before {
      @extend .icon-envelop;
    }
  }
}

.divide {
  position:relative;
  height:1px;
  margin:30px 0;
  text-align:center;
  background-image: linear-gradient(90deg, white 0%, $c-mh-red 50%, white 100%);

  &:after {
    position:absolute;
    left:50%;
    margin:-12px auto 0 -20px;
    background:#fff;
    display:block;
    width:40px;
    font-size:25px;
    color:$c-mh-red;
    @extend .icon;
  }
}

// Set height to panorama otherwise it is not visible. Width is auto 100p because it's a div.
.krpano {
  height: 500px;
  margin-bottom: 1em;
}

.tour-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 1em;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.property-agent-block {
  @include breakpoint(d) {
    background:$c-bg-light-grey;
    padding:10px;

    .divide {
      display:none;
    }
  }

  .divide:after {
    @extend .icon-user;
  }

  h2 {
    margin: 0 0 0.2em 0;
  }

  .agent-name {
    display: flex;

    > div {
      margin-right: 5px;
    }
  }
}

#block-views-property-agency-block {
  @include breakpoint(d) {
    background:$c-bg-light-grey;
    padding:10px;
  }

  .field-name-field-agency-logo img {
    width: 100%;
    max-width: 500px;
  }
}
