// Style view actions
.view-actions {
  background:$c-link;
  @include def-transition(background);
  border:1px solid darken($c-link, 5%);
  border-radius: 4px;
  display: block;
  overflow: hidden;

  a {
    color: #fff;
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 5px 10px;
    white-space:nowrap;

    &:nth-child(1) {
      border-bottom: 1px solid darken($c-link, 10%);
    }

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(2) {
      border-top: 1px solid lighten($c-link, 10%);
    }

    &:hover {
      background:lighten($c-link, 20%);
      color: darken($c-link, 25%);

      &:nth-child(1) {
        border-bottom-color: transparent;
      }
    }
  }
}
