.page-real-estate {
  #page-title {
    margin:0 0 10px 0;

    @include breakpoint(a,max) {
      display:none;
    }
  }

  #searchbox {
    // Display seachbox because default is hidden.
    display:block;
    text-align: left;

    .title {
      display:none;
    }

    .block-facetapi {
      background: none;
      border: none;

      &:after {
        display: none;
      }
    }
  }

  #search-customize-trigger {
    @include breakpoint(a) {
      display:block;
    }

    @include breakpoint(b) {
      display:none;
    }
  }

  #vtk-section-three .vtk-section-container {
    @include breakpoint(b) {
      display: grid;
      grid-template-columns: minmax(0, 4fr) minmax(0, 8.3fr);
      gap: 0 $gap;
    }

    @include breakpoint(c) {
      grid-template-columns: minmax(0, 3fr) minmax(0, 9.5fr);
    }

    > * {
      @include breakpoint(a) {
        grid-column: span 2;
      }
    }

    #sidebar-top {
      @include breakpoint(b) {
        grid-column: 1 / span 1;
        padding:0;
      }
    }

    #main-content {
      @include breakpoint(b) {
        grid-column: 2 / span 1;
      }
    }
  }

  #sidebar-top {
    // We do this styling in context of the search page,
    //because otherwise we sometimes get a empty grey bar if the content of this container is hidden.
    .sidebar-margin {
      @include breakpoint(a,max) {
        margin:0 $gap;
      }

      @include breakpoint(a) {
        background:$c-bg-light-grey;
        padding:10px;
      }
    }

    // Set background color of form elements on grey background.
    input {
      @include breakpoint(a) {
        background:#fff;
      }
    }

    .sidebar-container {

      @include breakpoint(a,max) {
        display: block !important;
      }

      @include breakpoint(a) {
        display: none;
      }

      @include breakpoint(b) {
        display: block !important;
      }
    }
  }

  // Alter default form styling.
  .form-item {
    padding:0;
  }

  #views-exposed-form-wrapper {
    @extend .clearfix;

    @include breakpoint(a) {
      background:$c-bg-light-grey;
      padding:10px;
    }

    // Set background color of form elements on grey background.
    input[type=text], select {
      width:100%;

      @include breakpoint(a) {
        background:#fff;
      }
    }
  }

  #views-exposed-form-property-search-page {
    display: flex;
    justify-content: space-between;
  }

  #edit-sort-bef-combine-wrapper {
    width:40%;
    margin-right: 0;

    .form-item {
      display: flex;
      justify-content: flex-end;
    }

    label {
      line-height:38px;
      text-align:right;
      padding:0 10px 0 0;
      white-space: nowrap;
    }
  }

  #views-exposed-form-wrapper .form-submit {
    right:auto;
    left:30%;
    top:10px;
    margin-left:-34px;
  }

  #block-search-api-saved-searches-properties {
    text-align:center;
    margin-top:1.7rem;
  }

  #search-api-saved-searches-save-form-wrapper {
    background: $c-bg-light-grey;
    max-height:0;
    overflow:hidden;
    @include def-transition(all);
    margin-top: 0;
    padding: 0;

    h3 {
      margin-top: 0;
    }

    form {
      @extend .clearfix;
      padding: 0 10px;
    }

    .form-item-mail {
      margin:0;

      @include breakpoint(a) {
        float:left;
        width:50%;
      }

      label {
        line-height:38px;
        padding-right:10px;

        @include breakpoint(a) {
          float:left;
        }
      }

      input {
        background:#fff;

        @include breakpoint(a) {
          float:left;
          width:60%;
        }
      }
    }

    .form-submit {
      margin-top: $gap;

      @include breakpoint(a) {
        float:left;
        margin-top: 0;
      }
    }
  }

  .property-list {
    margin-top: $gap;
  }

  .block-facetapi {
    h2 {
      display: block;
      font-size:1em;
      margin-bottom:0.2em;
    }

    ul {
      list-style:none;
      margin:0;
      padding-left:1em;
      line-height:1.3em;
    }

    .facetapi-active {
      font-size:0;
      line-height:0;
      margin-right:5px;
      text-decoration: none;

      &:before {
        font-size:16px;
        @extend .icon;
        @extend .icon-cancel-circle;
      }
    }

    .facetapi-limit-link {
      display: block;
      padding-left:1em;
      color: darken($c-bg-yellow, 15);

      &:after {
        @extend .icon-arrow-right;
        @extend .icon;
        margin:0 0.3em;
        display:inline-block;
        @include def-transition(transform);
        position:relative;
        bottom:-2px;
        color: darken($c-bg-yellow, 15);
      }

      &.open:after {
        transform: rotate(-90deg);
      }
    }

    .all-option {
      // @todo eventuell nicht nötig wenn der link durch einen anderen ersetzt wird
      display:none;
    }
  }
}

// Show class is set with javascript on clicking the trigger.
body.show-saved-searches {
  #search-api-saved-searches-save-form-wrapper {
    max-height:260px;
    margin-top: 1.7rem;
    padding: 1.5rem;

    @include breakpoint(a) {
      max-height:130px;
    }
  }
}

.facet-count {
  color:darken($c-bg-light-grey, 30%);
  margin-left:0.2em;
}

#search-empty {
  text-align: center;
  margin: 3em 0;
  background: #fff;
  padding: 10px;
}

#saved-searches-trigger {
  display:inline-block;
  padding:0 10px;
  line-height:36px;
  @include btn-color($c-bg-green);
  font-size:20px;
  color:#fff;
  width:auto;
  text-decoration: none;
}
