.node-type-article.page-node-view {
  .node-article {
    @include breakpoint(800) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 $gap;
    }

    @include breakpoint(b) {
      grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
    }
  }

  article header {
    font-weight: $font-bold;
  }

  .field-name-field-article-status {
    margin-bottom:0.5em;

    .field-items {
      padding:0.4em 0;
      font-size:1.3em;
      text-transform:uppercase;
      text-align:center;
      font-weight:$font-bold;
    }
  }
}

.field-name-field-article-status {
  .field-items {
    color:#fff;
  }

  // draft
  &.entity-id-1453 {
    .field-items {
      background:$c-bg-yellow;
    }
  }

  // active
  &.entity-id-1454 {
    .field-items {
      background:$c-bg-green;
    }
  }

  // archived
  &.entity-id-1455 {
    .field-items {
      background:$c-bg-light-grey;
      color:$c-light-grey;
    }
  }

  // Reset the color for the editable form element.
  .editablefield-item {
    color: $c-default;
  }
}
