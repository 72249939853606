body.node-type-request.page-node-view {
  #vtk-section-three .vtk-section-container {
    @include breakpoint(b) {
      display: grid;
      grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
      gap: 0 $gap;
    }

    > * {
      @include breakpoint(a) {
        grid-column: span 2;
      }
    }

    #main-content {
      @include breakpoint(b) {
        grid-column: 1 / span 1;
      }
    }

    #sidebar-bottom {
      background: none;
      padding: 0;

      @include breakpoint(b) {
        grid-column: 2 / span 1;
        padding:0;
      }
    }
  }

  .node-request {
    @include breakpoint(a) {
      display: grid;
      grid-template-columns: minmax(0, 5fr) minmax(0, 7fr);
      gap: 0 $gap;
    }

    @include breakpoint(b) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 $gap;
    }

    #content-first {
      // This is in content-first section to prevent styling the property links.
      ul.links {
        list-style:none;
        margin:2em 0;
        padding:0;
        text-align:center;

        li {
          display:inline;
        }
      }
    }
  }

  #block-views-request-agency-block-1,
  #block-views-request-requestor-block-1 {
    margin-bottom:2em;
    border-top:1px solid $c-light-grey;
    border-left:1px solid $c-light-grey;

    h2 {
      background:$c-bg-light-grey;
      padding:0.5em;
      font-weight:$font-normal;
      margin: 0;
      font-size: 1em;
    }

    .views-row {
      margin:0.5em;
    }

    .field {
      line-height:1.5em;
    }
  }

  .field-name-field-requestor,
  .field-name-field-request-message {
    margin-top:2em;
    border-top:1px solid $c-light-grey;
    border-left:1px solid $c-light-grey;

    .field-label {
      background:$c-bg-light-grey;
      padding:0.5em;
      font-weight:$font-normal;
    }

    .field-items {
      margin:0.5em;
    }

    // Reset styling on child fields.
    .field {
      border:none;
      line-height:1.5em;

      .field-label {
        background:none;
        padding:0;
        font-weight:$font-bold;
        width:auto;
        min-width:30%;
      }

      .field-items {
        margin:0;
      }
    }
  }

  #content-first .flag-wrapper a {
    display:inline-block;
    padding:0 10px;
    line-height:36px;
    font-size:20px;
    color:#fff;
    margin-bottom: 0.5em;
    @include btn-color($c-bg-green);

    &:hover {
      text-decoration:none;
    }

    &:after {
      @extend .icon-arrow-right;
      @extend .icon;
      margin-left:10px;
      position:relative;
      top:3px;
    }
  }

  .field-name-field-request-status {
    .field-items {
      padding:0.4em;
      font-size:1.3em;
      text-transform:uppercase;
      text-align:center;
      font-weight:$font-bold;
    }
  }
}

.field-name-field-request-status {
  .field-items {
    color:#fff;
  }

  // status pending
  &.entity-id-446 .field-items {
    background:$c-bg-yellow;
  }

  // status open
  &.entity-id-449 .field-items {
    background:$c-link;
  }

  // status accepted & auto forwarded
  &.entity-id-447 .field-items,
  &.entity-id-1635 .field-items {
    background:$c-bg-green;
  }

  // status rejected
  &.entity-id-448 .field-items {
    background:$c-bg-red;
  }

  // status invalid
  // not forwarded
  &.entity-id-1526,
  &.entity-id-1527 {
    .field-items {
      background:$c-bg-light-grey;
      color:$c-light-grey;
    }
  }

  .editablefield-item {
    color: $c-default;
  }
}
