// Contact page
.page-node-9708 {
  h1 {
    text-align: center;
    font-size: 2em;
    font-weight: $font-bold;

    @include breakpoint(a) {
      font-size: 2.5em;
    }
  }

  .node-contact_message-form {
    //@include breakpoint(a) {
    //  display: grid;
    //  grid-template-columns: repeat(2, minmax(0, 1fr));
    //  gap: 0 $gap;
    //}
    //
    //@include breakpoint(b) {
    //  display: block;
    //}

    .vertical-tabs,
    .form-actions {
      grid-column: 1 / span 2;
    }

    // Alter default styling, for optical reasons.
    select {
      width: 100%;

      @include breakpoint(a) {
        width:auto;
      }
    }

    .form-actions {
      clear:both;
    }
  }

  #main-content {
    max-width: 1000px;
    margin: 0 auto;

    @include breakpoint(a) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $gap;
    }
  }

  .node-page {
    //@include breakpoint(b) {
    //  grid-column: 2 / span 2;
    //  display: grid;
    //  grid-template-columns: repeat(2, minmax(0, 1fr));
    //  gap: $gap;
    //
    //  .divide {
    //    display:none;
    //  }
    //}
  }
}

#block-mh-contact-message-mh-contact-message {
  margin-bottom:4em;

  //@include breakpoint(b) {
  //  grid-column: 1;
  //  grid-row: 1;
  //}
}

#contact-info {
  @extend .clearfix;

  @include breakpoint(a) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 $gap;
  }

  @include breakpoint(b) {
    display: block;
  }

  .divide {
    @include breakpoint(a) {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
  }

  .divide:after {
    @extend .icon-info;
  }

  p:first-child {
    @include breakpoint(a) {
      margin-top: 0;
    }

    @include breakpoint(b) {
      margin-top: 1em;
    }
  }
}

#contact-reasons {
  .divide:after {
    @extend .icon-clipboard;
  }

  ul {
    list-style:none;
    margin:0;
    padding:0;
  }

  li {
    padding:0.5em;
    padding-left:60px;
    margin:1em 0;
    position:relative;

    &:before {
      @extend .icon;
      width:60px;
      text-align:center;
      position:absolute;
      left:0;
      top:40%;
      font-size:25px;
      color:$c-mh-red;;
    }

    &.search:before {
      @extend .icon-search;
    }
    &.sale:before {
      @extend .icon-tag;
    }
    &.partner:before {
      @extend .icon-user;
    }
  }

  h3 {
    margin: 0;
    font-size: 1em;
    color:$c-mh-red;;
  }

  p {
    margin:0;
  }
}

.divide.contact-form {
  display: block;

  @include breakpoint(a) {
    display: none;
  }

  &:after {
    @extend .icon-envelop;
  }
}
