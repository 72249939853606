@import "global";

/*
==========================================================================
Basic styling
==========================================================================
*/
// Font settings
body {
  font-family: 'Montserrat', sans-serif;
  font-size:100%;
  color:$c-default;
  font-weight:$font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Default to border-box
* {
  box-sizing: border-box;
}

// Paragraphs
p {
  line-height:1.6em;
}

h1 {
  font-size:1.7em;
  text-align:center;
  margin:0;
  background:#fff;
  padding:10px 10px 0 10px;

  @include breakpoint(a,max) {
    clear: both;
  }

  @include breakpoint(a) {
    text-align:left;
    margin-bottom: 10px;
    padding:0;
    background:none;
  }
}

h1 {
  font-weight:$font-normal;
}

h2, h3, h4 {
  font-weight:$font-medium;
}

h2 {
  font-size:1.2em;
}

// Fluid images
img {
  max-width:100%;
  height:auto;
}

// Reset fluid images
.messages img {
  width:auto;
}

// Links
a {
  color:$c-link;
  text-decoration:none;

  &:hover {
    color:lighten($c-link,20%);
    text-decoration:underline;
  }
}

.node-readmore a:after {
  content:' »';
}

// Style blockquotes.
blockquote {
  position:relative;
  margin:1em 60px;

  &:before {
    content: '\201C';
    font-size: 120px;
    font-family: georgia,serif;
    position: absolute;
    top: -20px;
    left: -60px;
    color: lighten($c-mh-red, 10%);
  }
}



/*
==========================================================================
Form styling
==========================================================================
*/
.form-wrapper {
  max-width:800px;
}

.page-admin-tmgmt .form-wrapper {
  max-width:none;
}

input,
textarea {
  padding: 0.5em;
  background: $c-bg-light-grey;
  border: 1px solid $c-light-grey;
  border-radius: 0;

  @include breakpoint(a,max) {
    width: 100%;
  }

  @include breakpoint(a) {
    width:auto;
    max-width:100%;
  }
}

input,
textarea,
select {
  &.error {
    border: 2px solid red;
  }
}

input[type="checkbox"],
input[type="radio"] {
  // Reset width on checkboxes and radios.
  width:auto;

  &.error {
    outline:2px solid red;
    border:none;
  }
}

select {
  padding:0.45em;
  background:$c-bg-light-grey;
  border: 1px solid $c-light-grey;
  // Height is only required for mac styling because padding don't work and we can't easily remove the default
  // appearance.
  height: 36px;
  border-radius: 0;

  @include breakpoint(a,max) {
    width:100%;
  }

  @include breakpoint(a) {
    width:auto;
    max-width:100%;
  }

  &:disabled {
    cursor:not-allowed;
    color: $c-light-grey;
  }
}

.form-item {
  margin-bottom:1em;
  padding:0.5em 0;
  line-height:1.5;

  .form-item {
    margin:0;
    padding:0.2em 0;
  }

  &.form-type-textarea {
    padding-bottom:0;
  }
}

fieldset.container-inline,
.field-type-image fieldset.form-wrapper,
.field-type-datetime fieldset.form-wrapper,
.field-type-date fieldset.form-wrapper {
  margin:0.6em 0 1em 0;
  padding:2.4em 1em 1em 1em;
  position:relative;
  background:$c-bg-light-grey;

  legend {
    font-weight:$font-bold;
    color:$c-mh-red;
    position:absolute;
    top:0.7em;
    left:1em;
  }

  input[type='text'],
  input[type='email'],
  select,
  textarea {
    background:#fff;
  }
}

.field-type-image {
  .image-widget-data {
    float:none;
  }

  .plupload_filelist {
    background:#fff;
  }
}

ul.tips,
div.description, {
  color:lighten($c-default, 40%);
  padding-top:0.2em;
  font-size:0.9em;
}

input[type='text'],
input[type='email'],
textarea {
  // Remove iPhone Shadow, DONT USE ON CHECKBOX OR RADIO!!!
  -webkit-appearance:none;
}


label {
  display:block;
  font-weight:$font-bold;
  color:$c-mh-red;

  &.option {
    display:inline;
    font-weight:$font-normal;
    color:$c-default;
  }
}

.field .field-label {
  font-weight: $font-bold;
}

.form-actions {
  text-align:center;
  margin:1em;
}

.form-submit {
  display:inline-block;
  margin:0 1em;
  padding:0 10px;
  line-height:36px;
  @include btn-color($c-bg-green);
  //font-size:20px;
  font-size:18px;
  color:#fff;
  width:auto;
}

// Add missing padding on some tabs.
div.horizontal-tabs {
  padding-bottom: 1em;
}

// Mark errors on horizontal tabs.
.horizontal-tabs ul.horizontal-tabs-list li.error a {
  color:red;
}



/*
==========================================================================
Elements styling
==========================================================================
*/
// Fix adminimal menu problem with top margin on mobile.
body.admin-menu.adminimal-menu:before {
  @include breakpoint(1024px,max) {
    display: none;
  }
}

// Prevent collapsing contextual links if nested
.contextual-links-region .contextual-links-region .contextual-links-wrapper {
  top:20px;
}

// Colorize the asterix
.form-required {
  color:red;;
}

// Hide the temp element wich will be filled with js and is used as cache.
#ajax-tmp {
  display:none;;
}

// Set styling to autocomplete results.
#autocomplete {
  border: 1px solid $c-light-grey;
  border-top:none;

  li {
    padding:0 0.5em;
    line-height:1.5em;

    &:hover {
      background-color:$c-bg-selection;
      color:#fff;
    }
  }
}

// Sticky elements on top.
.search-trigger, .request-trigger {
  &.sticky {
    // Default hidden
    display:none;
    position:fixed;
    top:0;
    right:0;
    width:80px;
    // Hide text
    font-size:0;
    box-shadow: 6px 0 10px rgba(0,0,0,0.5);

    &.show {
      // Show elements; Class toggle with javascript
      display:block;
    }
  }
}

// Request trigger.
.request-trigger {
  line-height:50px;
  @include click-area-color($c-mh-red);
  font-size:20px;
  color:#fff;
  outline:none;
  text-align:center;
  &:hover {
    color:#fff;
  }
  // Add icon
  &:after {
    position:absolute;
    right:0;
    width:80px;
    font-size:24px;
    line-height:50px;
    @extend .icon-envelop;
    @extend .icon;
  }
}

// Back to Top trigger.
.backtotop-trigger {
  display: none;
  color:#fff;
  outline:none;
  position:absolute;
  top:-25px;
  left:50%;
  margin-left:-25px;
  // Hide text
  font-size:0;

  @include breakpoint(a) {
    display: block;
  }

  &:hover {
    color:#fff;
    text-decoration:none;
  }

  // Add icon
  &:after {
    @include click-area-color($c-mh-red);
    border-radius: 2px;
    padding:10px;
    font-size:20px;
    line-height:50px;
    @extend .icon-arrow-up;
    @extend .icon;
  }
}

// Mask which overlaps the whole content if the slide-in navigation is visible.
// So you only could click the mask and not the content. When you click the mask
// the it triggers the slide-out action of the nav.
#mask {
  visibility:hidden;
  position:absolute;
  top:0;
  bottom:0;
  width:100%;
  background:rgba(0,0,0,0.01);
  cursor:pointer;
  z-index:999;
  box-shadow: 0 0 20px black;
  @include def-transition(margin-left);
}

.show-nav #mask {
  visibility:visible;
}

// Breadcrumb.
#breadcrumb {
  @include breakpoint(a,max) {
    // Hide breadcrumb on smartphones.
    display:none;
  }

  ul {
    list-style:none;
    margin:0;
    padding:0;
  }

  li {
    display:inline;

    &:after {
      @extend .icon-arrow-right;
      @extend .icon;
      margin:0 0.3em;
      color:lighten($c-default,30%);
      font-size:0.7em;
    }

    // Remove arrow from last element.
    &:last-child:after {
      content:'';
    }
  }
}

// Reset box-sizing for drag handle.
a.tabledrag-handle .handle {
  box-sizing: content-box;
}

// Style pager.
ul.pager {
  clear:both;
  text-align:center;
  padding:1em 0;
  list-style:none;
  margin:0;

  li {
    display:inline;
    padding:0.4em;
    font-size:1.2em;
  }

  .pager-current {
    font-weight:$font-bold;
  }
}


// Hide the secondary tabs on node edit form. We only use this to hide the
// language links.
.page-node-edit .tabs.secondary {
  display:none;
}

// Indention class.
div.indentation {
  width: 40px;
}

// Style back button (history-1)
#back-button {
  @include breakpoint(a,max) {
    display: none;
  }

  border:none;
  background:none;
  color:$c-link;
  padding:0 0 0.2em 0;
  margin:0;

  &:hover {
    color:lighten($c-link,20%);
    text-decoration:underline;
  }
}

// Set default styling for dates
.date {
  color:lighten($c-default, 50%);
  font-size:0.9em;
  margin-bottom:0.2em;
}

// Display all languages in select on translation cart form.
.form-item-target-language select[multiple='multiple'] {
  height:340px;
}

// Passwort form
.form-item .confirm-parent,
.form-item .password-parent {
  width:100%;
  padding:0;
}

.password-strength,
input.password-confirm,
div.block-formblock input.password-confirm,
input.password-field,
div.block-formblock input.password-field,
div.password-confirm {
  width:48%;
}

input.password-confirm {
  margin:0;
}

.password-suggestions {
  display:none !important;
}

// Add horizontal scroll bar to views tables. The only reason why this don't effect the other views is because this
// wrapper was removed with templates. Normally we add a global (body) scrollbar, but because we use a off-canvas menu
// on smartphones we could not do this.
.view .view-content {
  @include breakpoint(a,max) {
    overflow-x: scroll;
  }
}

// We use the editable link as a transparent link area for the whole field. We remove the text in the mh_misc module.
.editablefield-hover-link {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Hide the submit button of the editable field because we don't use it.
.editablefield-edit-hover.form-submit {
  display: none;
}

// Hide the required star on editable fields.
.editablefield-item .form-required {
  display: none;
}

// Style bookmark flag as star.
.flag-wrapper.flag-bookmarks {
  a {
    font-size: 0;
    @include def-transition(all);

    &:after {
      @extend .icon-star;
      @extend .icon;
      font-size:16px;
    }

    &:hover {
      text-decoration: none;
    }

    &.unflag-action {
      &:after {
        @extend .icon-star2;
        color:#ffff00;
      }
    }
  }
}

// Styling action links.
.action-links {
  padding: 0 10px;
  margin: 0;
  background: #fff;

  @include breakpoint(a) {
    padding: 0.2em 0;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    margin-right: 1em;

    &:before {
      content: "+ ";
    }
  }
}

.views-table .views-field ul {
  margin: 0;
}

// Style matching search clients link.
.matching-search-clients-link {
  line-height:36px;
  font-size:20px;
  @include btn-color($c-bg-yellow);
  padding:0 $gap;
  color: darken($c-bg-yellow, 35%);
  display: block;
  margin:$gap auto;
  width: 75%;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: darken($c-bg-yellow, 35%);
  }

  &:after {
    @extend .icon-arrow-right;
    @extend .icon;
    margin-left:10px;
    position:relative;
    top:3px;
  }
}

// Reset styling of l10n client.
#l10n-client {
  .form-submit {
    font-size: 16px;
    margin: 0 5px;
    @include btn-color(#37a);
    color: #fff;
  }

  #l10n-client-search-form {
    height: auto;

    #l10n-client-search-filter-clear {
      display: none;
    }
  }
}


/*
==========================================================================
Layout styling
==========================================================================
*/
body {
  @include breakpoint(a,max) {
    background:$c-grey-two;
  }
}

#page-wrapper {
  // Required for #mask full height
  position:relative;

  // Normally we don't hide the overflow to get horizontal scroll bars for big tables. Because we use a off-canvas menu
  // for smartphones we need to hide the overflow there. In this case we add a scrollbar to the tables itself.
  @include breakpoint(a,max) {
    overflow: hidden;
  }
}

// Add Transition to responsive navigation slide out/in
#navigation,
#vtk-section-two,
#vtk-section-local-tasks,
#vtk-section-three,
#vtk-section-four,
#vtk-section-five,
#header,
#mask {
  @include breakpoint(a,max) {
    @include def-transition(margin-left);
  }
}

.vtk-section {
  width:100%;
}

.vtk-section-container {
  @include breakpoint(b) {
    max-width: 1640px;
    margin: 0 auto;
  }
}

/*
==========================================================================
Vertical section one
==========================================================================
*/
#vtk-section-one {
  @include breakpoint(a) {
    // Set full width background from $sw-gt-alpha, because otherwise the background
    // will be visible in the slide-in navigation
    background:$c-bg-light-grey;
    // Add spacing to the bottom for the logo.
    margin-bottom:50px;
  }

  .vtk-section-margin {
    @include breakpoint(a) {
      margin: 0 $gap;
    }
  }

  .vtk-section-container {
    position:relative;
    // Prevent overlapping of the slideshow on the logo.
    z-index:100;
  }
}

#header {
  position:relative;
  height:100px;
  width:100%;

  @include breakpoint(a,max) {
    // On bigger sizes the background will be set on the vertical-section as
    // full with.
    background:$c-grey-one;
  }
}

#logo {
  display:block;
  width:100%;
  text-align:center;

  @include breakpoint(a) {
    width:300px;
  }

  svg {
    width:200px;
    margin-top:38px;
    fill: white;

    @include breakpoint(a) {
      fill: black;
      width:250px;
    }

    @include breakpoint(b) {
      width:300px;
    }
  }
}

#nav-trigger {
  position:absolute;
  top:50px;
  right:0;
  height:50px;
  line-height:50px;
  width:80px;
  color:#fff;
  text-align:center;
  padding:0;
  border:none;
  outline:none;
  background:transparent;
  // Hide text
  font-size:0;
  // Add icon
  &:before {
    font-size:24px;
    line-height:50px;
    @extend .icon-navicon;
    @extend .icon;
  }

  @include breakpoint(a) {
    // Hide the nav-trigger button.
    display:none;
  }
}

#language-switcher {
  position:absolute;
  top:0;
  right:0;
  z-index:101;

  button {
    height:50px;
    line-height:50px;
    width:80px;
    color:#fff;
    text-align:center;
    padding:0;
    border:0;
    outline:none;
    background:transparent;
    // Hide text because on smartphones we only use the globe icon.
    font-size:0;

    @include breakpoint(a) {
      // Reset font size to it's default.
      font-size:inherit;
      // Reset width to it's default.
      width:auto;
      // Add the same padding like the other navigation elements have.
      padding:0 10px;
      // Add the same transition like the other navigation elements have.
      @include def-transition(background);

      &:hover {
        // Add the same background hover effect like the other navigation
        // elements have.
        background:darken($c-bg-light-grey, 10%);
      }
    }

    // Add globe icon
    &:before {
      font-size:24px;
      line-height:50px;
      @extend .icon-earth;
      @extend .icon;

      @include breakpoint(a) {
        // Hide globe icon on bigger screens than smartphones.
        display:none;
      }
    }
  }

  &.expand {
    button {
      background:$c-grey-one;
    }

    .language-switcher-locale-url {
      display:block;
    }
  }
}

.language-switcher-locale-url {
  display:none;
  width:16em;
  position:absolute;
  right:0;
  background:$c-grey-one;
  margin:0;
  padding:0;
  list-style:none;

  li {
    float:left;
    display:block;
    width:percentage(1/3);
  }

  .highlight a {
    color: #fff;
    font-weight: $font-bold;
  }

  a {
    color: desaturate(lighten($c-grey-one, 50%), 10%);
    text-decoration:none;
    display:block;
    line-height:50px;
    text-align:center;
    // Add the same transition like the other navigation elements have.
    @include def-transition(background);

    &:hover {
      // Add the same background hover effect like the other navigation
      // elements have.
      background:lighten($c-grey-one, 20%);
      color: white;
    }
  }
}

#currency-switcher {
  position:absolute;
  top:0;
  right:89px;
  z-index:101;

  .currency-switcher-active {
    height:50px;
    line-height:50px;
    color:#fff;
    text-align:center;
    border:0;
    outline:none;
    background:transparent;

    @include breakpoint(a) {
      // Fix width to prevent text center jumping.
      width:49px;
      // Add the same padding like the other navigation elements have.
      padding:0 10px;
    }

    // Add the same transition like the other navigation elements have.
    @include def-transition(background);

    &:hover {
      // Add the same background hover effect like the other navigation
      // elements have.
      background:lighten($c-grey-two, 10%);

      @include breakpoint(a) {
        background:darken($c-bg-light-grey, 10%);
      }
    }
  }

  &.expand {
    .currency-switcher-active {
      background:$c-grey-one;
    }

    .currency-switcher-list {
      display:block;
    }
  }

  .currency-switcher-list {
    li {
      display:block;
    }
  }
}

.currency-switcher-list {
  display:none;
  background:$c-grey-one;
  margin:0;
  padding:0;
  list-style:none;

  .currency-switcher-item {
    background:none;
    border:none;
    margin:0;
    padding:0;
    color: desaturate(lighten($c-grey-one, 50%), 10%);
    line-height:50px;
    text-align:center;
    width:100%;
    // Add the same transition like the other navigation elements have.
    @include def-transition(background);

    &:hover {
      // Add the same background hover effect like the other navigation
      // elements have.
      background:lighten($c-grey-one, 20%);
      color: white;
    }
  }
}

.show-nav {
  #currency-switcher {
    // Styling for slide-in navigation only.
    @include breakpoint(a,max) {
      position:static;
      border-top:1px solid lighten($c-grey-two, 10%);
    }

    .currency-switcher-active {
      // Styling for slide-in navigation only.
      @include breakpoint(a,max) {
        padding-left:20px;
        width:100%;
        text-align:left;
        border-top:1px solid lighten($c-grey-two, 10%);
      }
    }
  }
}

#unit-switcher {
  position:absolute;
  top:0;
  right:138px;
  z-index:101;

  .unit-switcher-active {
    height:50px;
    line-height:50px;
    color:#fff;
    text-align:center;
    border:0;
    outline:none;
    background:transparent;

    @include breakpoint(a) {
      // Fix width to prevent text center jumping.
      width:49px;
      // Add the same padding like the other navigation elements have.
      padding:0 10px;
    }

    // Add the same transition like the other navigation elements have.
    @include def-transition(background);

    &:hover {
      // Add the same background hover effect like the other navigation
      // elements have.
      background:lighten($c-grey-two, 10%);

      @include breakpoint(a) {
        background:darken($c-bg-light-grey, 10%);
      }
    }
  }

  &.expand {
    .unit-switcher-active {
      background:$c-grey-one;
    }

    .unit-switcher-list {
      display:block;
    }
  }

  .unit-switcher-list {
    li {
      display:block;
    }
  }
}

.unit-switcher-list {
  display:none;
  background:$c-grey-one;
  margin:0;
  padding:0;
  list-style:none;

  .unit-switcher-item {
    background:none;
    border:none;
    margin:0;
    padding:0;
    color: desaturate(lighten($c-grey-one, 50%), 10%);
    line-height:50px;
    text-align:center;
    width:100%;
    // Add the same transition like the other navigation elements have.
    @include def-transition(background);

    &:hover {
      // Add the same background hover effect like the other navigation
      // elements have.
      background:lighten($c-grey-one, 20%);
      color: white;
    }
  }
}

.show-nav {
  #unit-switcher {
    // Styling for slide-in navigation only.
    @include breakpoint(a,max) {
      position:static;
      border-top:1px solid lighten($c-grey-two, 10%);
    }

    .unit-switcher-active {
      // Styling for slide-in navigation only.
      @include breakpoint(a,max) {
        padding-left:20px;
        width:100%;
        text-align:left;
        border-top:1px solid lighten($c-grey-two, 10%);
      }
    }
  }
}

/*
==========================================================================
Navigation / Menu styling
==========================================================================
*/
#navigation {
  @include breakpoint(a,max) {
    position:absolute;
    top:0;
    width:100%;
    // Correct full width
    padding-right:80px;
    margin-left:100%;
  }

  .menu {
    margin:0;
    padding:0;
    list-style:none;

    @include breakpoint(a) {
      text-align:right;
    }

    a {
      color:#fff;
      text-decoration:none;
      line-height:50px;
      display:block;
      @include def-transition(background);

      // Styling for slide-in navigation only.
      @include breakpoint(a,max) {
        padding-left:20px;
        border-top:1px solid lighten($c-grey-two, 10%);
      }

      @include breakpoint(a) {
        padding:0 10px;
        color:$c-default;
      }

      &:hover {
        background:lighten($c-grey-two, 10%);

        @include breakpoint(a) {
          background:darken($c-bg-light-grey, 10%);
        }
      }
    }
  }

  li {
    @include breakpoint(a) {
      display:inline-block;
    }
  }

  .node-59718 a {
    @include btn-color(darken($c-bg-green,3%));
    border-radius: 0;
    color: #fff;

    @include breakpoint(a) {
      border-radius: 4px;
      margin-left: 10px;
    }
  }
}

#main-menu {
  @include breakpoint(a) {
    position:absolute;
    top:50px;
    right:0;
  }

  .menu a {
    @include breakpoint(a) {
      line-height: 40px;
    }
  }
}

#block-system-user-menu {
  @include breakpoint(a) {
    position:absolute;
    top:0;
    // With of language text with 10px padding left and right.
    right:187px;
  }
}

// Style secondary menu.
#navigation #block-system-user-menu a,
#unit-switcher .unit-switcher-active,
#currency-switcher .currency-switcher-active,
#language-switcher button {
  @include breakpoint(a) {
    color: desaturate(lighten($c-grey-one, 50%), 10%);
    font-size: 0.9em;
  }
}

/*
==========================================================================
Vertical section two
==========================================================================
*/
#vtk-section-two {
  @include breakpoint(a,max) {
    // Border only for search trigger, but it is set to the section so the sticky
    // element doesn't have the border because of the same class.
    border-top:1px solid desaturate(lighten($c-grey-one,40%),20%);
  }
  // Required that the search icon will slide out if slinde-in nav is active.
  position:relative;

  .vtk-section-margin {
    @include breakpoint(a) {
      margin:0 $gap;
    }
  }
}

// This is a class because the sticky elements style is the same
.search-trigger {
  width:100%;
  border:none;
  line-height:50px;
  @include click-area-color($c-mh-red);
  font-size:20px;
  color:#fff;
  outline:none;
  // Add icon
  &:after {
    position:absolute;
    right:0;
    width:80px;
    font-size:24px;
    line-height:50px;
    @extend .icon-search;
    @extend .icon;
  }

  @include breakpoint(a) {
    display:none;
  }
}

/*
==========================================================================
Vertical section three
==========================================================================
*/
#vtk-section-three {
  .vtk-section-margin {
    margin: $gap/2;

    @include breakpoint(a) {
      margin: $gap;
    }
  }

  .vtk-section-container {
    @include breakpoint(a,max) {
      max-width:518px;
      margin:0 auto;
    }
  }
}

// #sidebar-top styling happens in _search_ui.scss because it is only used for
// this.

#main-content {
  @include breakpoint(a,max) {
    // Default background
    background:#fff;
    // Default content space
    padding:10px;
  }
}

// Reset defaults on listing pages
.front,
.page-real-estate,
.page-user-bookmarks {
  #main-content {
    background:transparent;
    padding:0;
  }
}

#sidebar-bottom {
  // Clear for next vertical section.
  @extend .clearfix;

  @include breakpoint(a) {
    background:$c-bg-light-grey;
    padding:10px;
  }
}


/*
==========================================================================
Vertical section four
==========================================================================
*/
#vtk-section-four {
  clear:both;
  @extend .clearfix;

  @include breakpoint(a,max) {
    margin-bottom:10px;
  }

  @include breakpoint(a) {
    padding-top:3em;
    background:$c-grey-one;
  }

  .vtk-section-margin {
    @include breakpoint(a) {
      margin:0 $gap;
    }
  }

  .vtk-section-container {
    @include breakpoint(a,max) {
      max-width: 550px;
      margin: 0 auto;
    }

    @include breakpoint(a) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 $gap;
    }

    @include breakpoint(b) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .block {
    @include breakpoint(a,max) {
      padding:0 10px;
    }
  }

  h2 {
    color:#fff;
    margin-bottom:1.5em;

    @include breakpoint(a,max) {
      display:none;
    }
  }

  .menu {
    margin:0;
    padding:0;
    list-style:none;

    a {
      display:block;
      line-height:2em;
      color:lighten($c-grey-one,40%);

      &:hover {
        color:saturate(lighten($c-grey-one,70%),50%);
        text-decoration:none;
      }

      &:before {
        @extend .icon;
        @extend .icon-radio-unchecked;
        font-size:0.8em;
        margin-right:1em;

        @include breakpoint(a,max) {
          display:none;
        }
      }
    }
  }
}


/*
==========================================================================
Vertical section five
==========================================================================
*/
#vtk-section-five {
  clear:both;
  padding:10px;
  padding-top:30px;
  background:$c-grey-one;
  text-align:center;

  @include breakpoint(a,max) {
    // Bottom get +50px because of the back to top link.
    padding-bottom:60px;
  }

  .vtk-section-margin {
    @include breakpoint(a) {
      margin:0 $gap;
    }
  }

  .vtk-section-container {
    @include breakpoint(a) {
      border-top:1px solid $c-mh-red;
      position:relative;
      padding:4em 0 2em 0;
      margin-top:4em;
    }
  }

  .menu {
    margin:0;
    padding:0;

    li {
      display:inline;
      margin:0 10px;
    }

    a {
      text-decoration:none;
      color:lighten($c-grey-one,40%);

      &:hover {
        color:saturate(lighten($c-grey-one,70%),50%);
        text-decoration:none;
      }
    }

    [href*="facebook"],
    [href*="twitter"],
    [href*="google"] {
      font-size:0;

      &:before {
        font-size:36px;
        @extend .icon;
      }
    }

    [href*="facebook"] {

      &:before {
        @extend .icon-facebook;
      }
    }

    [href*="twitter"] {

      &:before {
        @extend .icon-twitter;
      }
    }

    [href*="google"] {

      &:before {
        @extend .icon-googleplus;
      }
    }
  }
}

#block-menu-menu-social-menu {
  margin-top:2em;
}

#call {
  display:block;
  width:60px;
  height:60px;
  margin:20px auto;
  @include click-area-color($c-mh-red);
  border:4px solid #fff;
  border-radius: 35px;
  box-shadow: 0 0 6px rgba(black,0.5);
  text-align:center;
  text-decoration:none;
  color:#fff;
  line-height: 60px;
  font-size:0;

  &:after {
    @extend .icon-phone;
    @extend .icon;
    font-size:34px;
    line-height:53px;
  }

  &:hover {
    color:#fff;
  }

  @include breakpoint(a) {
    display:none;
  }
}
