body.node-type-profile {
  #vtk-section-local-tasks .extract-path {
    display:none;
  }

  article.node-profile {
    display: flex;
    flex-wrap: wrap;

    fieldset {
      border: none;
      padding: 0;
      margin: 0 1em 0 0;
    }

    .field {
      margin:0.2em 0;
    }
  }

  #main-content {
    .block-views {
      clear: both;
      margin-bottom: 4em;

      .view {
        @include breakpoint(a) {
          box-shadow: 0 1px 3px rgba(black, 0.2);
        }
      }
    }
  }
}

#block-mh-profile-mh-profile-dashboard {
  margin:2em 0 1em 0;
  @extend .clearfix;

  .dashboard-links {
    margin:0;
    padding:0;

    li {
      float:left;
      display:block;

      &:first-child a {
        margin-left:0;
      }
    }

    a {
      @include btn-color($c-link);
      color:#fff;
      display:block;
      margin: 0.5em;
      padding:1em;
      text-align:center;

      &:hover {
        text-decoration:none;
      }

      &:before {
        display:block;
        font-size:40px;
        margin-bottom:0.2em;
        @extend .icon;
      }
    }

    .add-property a {
      &:before {
        @extend .icon-add-property;
      }
    }
    .bookmarks a {
      &:before {
        @extend .icon-star2;
      }
    }
    .saved_searches a {
      &:before {
        @extend .icon-search;
      }
    }
    .properties-agency a {
      &:before {
        @extend .icon-manage-properties;
      }
    }
    .requests-agency a {
      &:before {
        @extend .icon-manage-requests;
      }
    }
    .edit a {
      &:before {
        @extend .icon-user-edit;
      }
    }
  }
}

#block-views-properties-block-1 {
  .form-submit {
    display: none;
  }
}
