/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../fonts/montserrat-v26-latin-300.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/montserrat-v26-latin-regular.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../fonts/montserrat-v26-latin-500.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../fonts/montserrat-v26-latin-700.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
