div.messages {
  border: 1px solid;
  border-left-width: 8px;
  margin: 1em 0;
  padding: 0.8em 0.8em 0.8em 3em;
  position:relative;
  font-weight:$font-normal;

  &:before {
    @extend .icon;
    font-size:1.2em;
    position:absolute;
    left:0.8em;
  }
}

div.status {
  border-color: desaturate(lighten($c-bg-green, 15%), 25%);

  &:before {
    @extend .icon-checkmark-circle;
  }
}

div.status,
.ok {
  color: desaturate(darken($c-bg-green, 10%), 25%);
}

div.status,
table tr.ok {
  background-color: desaturate(lighten($c-bg-green, 58%), 20%);
}

div.warning {
  border-color: lighten($c-bg-yellow, 5%);

  &:before {
    @extend .icon-notification;
  }
}

div.warning,
.warning {
  color: darken($c-bg-yellow, 25%);
}

div.warning,
table tr.warning {
  background-color: desaturate(lighten($c-bg-yellow, 44%), 20%);
}

div.error {
  border-color: desaturate(lighten($c-bg-red, 10%), 15%);

  &:before {
    @extend .icon-cancel-circle;
  }
}

div.error,
.error {
  color: desaturate($c-bg-red, 10%);
}

div.error,
table tr.error {
  background-color: lighten($c-bg-red, 57%);
}

div.error p.error {
  color: #333;
}

div.messages ul {
  margin: 0 0 0 1em;
  padding: 0;
}
div.messages ul li {
  list-style-image: none;
}
