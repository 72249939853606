.node-property.node-teaser {
  @extend .clearfix;
  background:#fff;
  position:relative;

  @include breakpoint(a) {
    border:1px solid $c-lighter-grey;
    @include def-transition(all);

    &:hover {
      box-shadow: 0 0 10px rgba(black, 0.4);
      background:$c-bg-light-grey;
      border-color:$c-light-grey;
    }
  }

  @include breakpoint(b) {
    // Hide outside content for hidden bookmark buttons...
    overflow: hidden;

    // 2021-06-09 dont hide bookmark
    // &:hover {
    //   .property-flags .flag-bookmarks a {
    //     right: 0px;
    //   }
    // }
  }

  header {
    a {
      display:block;
      color:$c-default;
      text-decoration:none;

      &:hover {
        color:#000;
        text-decoration:underline;
      }
    }
  }

  .img-wrapper {
    width: 100%;
    padding-bottom: 67.6%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  img {
    display:block;
    position: absolute;

    &.portrait {
      height: 100%;
      width: auto;
    }

    &.landscape {
      width: 100%;
      height: auto;
    }
  }

  h2 {
    margin:0;
    padding:10px 10px 5px 10px;
    // Prevent linebreak.
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  aside {
    padding:0 10px 10px 10px;
    @extend .clearfix;
  }

  .field-country-city {
    .shs-hierarchy {
      padding: 0;
    }

    li {
      display: inline;

      // Reset default separator
      &:after {
        content: "";
        display: none;
      }
    }

    // Add separator to the city element so it is automatically not
    // visible if the city is hidden.
    .shs-term-selected:before {
      content: '/';
      margin: 0 0.5em;
    }
  }

  //.shs-hierarchy {
  //  padding:0;
  //}
  //
  //.shs-hierarchy li {
  //  display:inline;
  //
  //  &:after {
  //    content: '/';
  //  }
  //
  //  &.shs-term-selected:after {
  //    content: '';
  //  }
  //}

  // Hide city if is set to hidden.
  //&.hide-city {
  //  .shs-hierarchy li:after {
  //    content: '';
  //  }
  //
  //  .shs-term-selected {
  //    display: none;
  //  }
  //}

  .field-property-price,
  .field-price-on-request,
  .field-property-sold-status {
    clear:both;
    float:left;
  }

  .links {
    padding:0;
    list-style:none;
    clear:both;
  }

  .property-flags {
    position: absolute;
    right: 0;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .top,
    .panorama-flag,
    .tour-flag,
    .field-property-sold-status,
    .flag-bookmarks a {
      line-height:32px;
      font-size: 16px;
      color: #fff;
      background: $c-mh-red;
      padding:0 10px;
      margin-bottom: 4px;
      height: 32px;
    }

    .flag-bookmarks a {
      position: relative;
      display: block;
      color: white;
      font-size: 0;
      background:rgba(0, 0, 0, 0.5);

      // 2021-06-09 dont hide bookmark
      // @include breakpoint(b) {
      //   right: -100%;
      // }

      &:after {
        line-height: 32px;
      }

      &:hover {
        background:rgba(0, 0, 0, 1);
      }

      &.unflag-action {
        right: 0px;
      }
    }
  }
}
