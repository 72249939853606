.field-name-field-assignee {
  .field-items {
    //color:#fff;
  }

  // Martin Wimmer
  &.entity-id-368 {
    .field-items {
      background: $c-bg-n1;
    }
  }

  // Roman Dollberger
  &.entity-id-364 {
    .field-items {
      background:$c-bg-n2;
    }
  }

  // Thomas Reifetshammer
  &.entity-id-3118 {
    .field-items {
      background:$c-bg-n3;
    }
  }

  // Gundula Pichler
  &.entity-id-78125 {
    .field-items {
      background:$c-bg-n4;
    }
  }

  // Renate Sulzer
  &.entity-id-37143 {
    .field-items {
      background:$c-bg-n5;
    }
  }

  // Eva Horak
  &.entity-id-12094 {
    .field-items {
      background:$c-bg-n6;
    }
  }

  .editablefield-item {
    color: $c-default;
  }
}

.field-name-field-task-type {
  .field-items {
    color:#fff;
  }

  // Business appointment
  &.entity-id-1523 {
    .field-items {
      background:$c-link;
    }
  }

  // Call
  &.entity-id-202 {
    .field-items {
      background:$c-bg-yellow;
    }
  }

  // E-Mail
  &.entity-id-203 {
    .field-items {
      background:$c-bg-pink;
    }
  }

  // ToDo
  &.entity-id-204 {
    .field-items {
      background:$c-bg-orange;
    }
  }

  .editablefield-item {
    color: $c-default;
  }
}

.field-name-field-task-status {
  .field-items {
    color:#fff;
  }

  // in progress
  &.entity-id-454 {
    .field-items {
      background:$c-bg-yellow;
    }
  }

  // closed
  &.entity-id-455 {
    .field-items {
      background:$c-bg-green;
    }
  }

  // open
  &.entity-id-453 {
    .field-items {
      background:$c-bg-red;
    }
  }

  .editablefield-item {
    color: $c-default;
  }
}

.field-name-field-task-priority {
  .field-items {
    text-align:center;
  }

  .field-item {
    font-size:0;

    &:before {
      @extend .icon;
      font-size:20px;
    }
  }

  // critical
  &.entity-id-450 {
    .field-item:before {
      @extend .icon-arrow-up-right;
      color:$c-bg-red;
    }
  }

  // trival
  &.entity-id-452 {
    .field-item:before {
      @extend .icon-arrow-down-left;
      color:$c-bg-green;
    }
  }

  // major
  &.entity-id-451 {
    .field-item:before {
      @extend .icon-arrow-right2;
      color:$c-bg-yellow;
    }
  }

  .editablefield-item {
    font-size: initial;
  }
}
